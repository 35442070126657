import { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Alert,
  Spinner,
  Form,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { sessionType, genderType, teamScoreType } from '../../redux/_constants';
import {
  sessionActions,
  alertActions,
  adminActions,
} from '../../redux/_actions';
import * as Yup from 'yup';
import * as Defaults from '../../utilities/constants';
import {
  strApparatusToArray,
  arrayToStrApparatus,
  /*dateTime,*/ displayName,
} from '../../utilities/conversions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TeamSelector from '../setup/teamselector';
import { dataUtilities } from '../../utilities/data';
import { prettyTeamScoring } from '../../utilities/scoring';
import { useHistory } from 'react-router-dom';
import { JudgePanelType } from '../../models';

function SessionForm(props) {
  const { setup, gender, reset } = props;
  const [isCreating, setIsCreating] = useState(false);
  const [footerDisabled, setFooterDisabled] = useState(false);
  const [teamSelector, setTeamSelector] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { alert } = useSelector((state) => state);
  const adminTeams = useSelector((state) => state.admin.teams);
  const [teams, setTeams] = useState(adminTeams);
  const { MALE, FEMALE, COED } = genderType;
  const { SOLO, DUAL, MULTI } = sessionType;
  const {
    MEDIAURL,
    mApparatusAbbv,
    wApparatusAbbv,
    soloDefault,
    dualDefault,
    dualNCAAMDefault,
    dualNCAAWDefault,
    multiDefault,
    multiNCAAMDefault,
    multiNCAAWDefault,
  } = Defaults;

  const abbv =
    gender === MALE ? mApparatusAbbv : gender === FEMALE ? wApparatusAbbv : [];

  const isMALE = gender === MALE;
  const isFEMALE = gender === FEMALE;

  useEffect(() => {
    if (!alert.clear) {
      setIsCreating(false);
    }
  }, [alert.clear]);

  // TODO: refactor to move admin function to data function
  useEffect(() => {
    if (teams.allIds.length === 0) {
      dispatch(adminActions.getTeams());
    }
  }, [dispatch, teams]);

  const validationSchema = Yup.object().shape({
    judging: Yup.boolean(),
    judgingRequired: Yup.boolean(),
    judgePanel: Yup.string(),
    now: Yup.boolean(),
    name: Yup.string(),
    judgeCount: Yup.number(),
    teamA: Yup.string().nullable(),
    teamB: Yup.string().nullable(),
    lineup: Yup.boolean(),
    competition: Yup.boolean(),
    apparatus: Yup.string(),
    startAt: Yup.string(),
    teamScoring: Yup.string().required('Required'),
    alternating: Yup.boolean(),
  });

  const getDefault = () => {
    let result = null;

    if (setup === SOLO) {
      result = {
        ...soloDefault,
        gender: gender,
        apparatus: strApparatusToArray(
          soloDefault.apparatus.substring(0, abbv.length)
        ),
      };
    }

    if (setup === DUAL) {
      result = {
        ...dualDefault,
        ...(isMALE ? dualNCAAMDefault : {}),
        ...(isFEMALE ? dualNCAAWDefault : {}),
        apparatus: strApparatusToArray(
          dualDefault.apparatus.substring(0, abbv.length)
        ),
      };
    }

    if (setup === MULTI) {
      result = {
        ...multiDefault,
        ...(isMALE ? multiNCAAMDefault : {}),
        ...(isFEMALE ? multiNCAAWDefault : {}),
        apparatus: strApparatusToArray(
          multiDefault.apparatus.substring(0, abbv.length)
        ),
      };
    }

    return result;
  };

  const { setFieldValue, handleSubmit, handleChange, values } = useFormik({
    initialValues: getDefault(),
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      setIsCreating(true);
      const input = {
        ...values,
        type: setup,
        name: calcSessionName(),
        apparatus: arrayToStrApparatus(values.apparatus),
        judgePanel: getJudgePanel(gender),
      };

      const teamChange = dataUtilities.checkSessionTeamChange(input);

      dispatch(alertActions.clear());
      dispatch(sessionActions.create(input, teamChange, history));
    },
  });

  const getJudgePanel = (gender) => {
    const { NCAAW2, NCAAM, OPEN } = JudgePanelType;

    switch (gender) {
      case MALE:
        return NCAAM;
      case FEMALE:
        return NCAAW2;
      case COED:
      default:
        return OPEN;
    }
  };

  const calcSessionName = () => {
    const getName = (teamId) => {
      return displayName(teams.byId[teamId].name, teams.byId[teamId].altNames);
    };

    const {
      teamA,
      teamB,
      teamC,
      teamD,
      teamE,
      teamF,
      teamG,
      teamH,
      competition,
      name,
    } = values;
    let result = teamA === '' ? 'Team ?' : getName(teamA);

    if (name === '') {
      if (setup === DUAL) {
        result += ' vs ';
        result += teamB === '' ? 'Team ?' : getName(teamB);
      }

      if (setup === MULTI) {
        result += teamB ? ` / ${getName(teamB)}` : '';
        result += teamC ? ` / ${getName(teamC)}` : '';
        result += teamD ? ` / ${getName(teamD)}` : '';
        result += teamE ? ` / ${getName(teamE)}` : '';
        result += teamF ? ` / ${getName(teamF)}` : '';
        result += teamG ? ` / ${getName(teamG)}` : '';
        result += teamH ? ` / ${getName(teamH)}` : '';
      } else {
        result += `${competition ? '' : ' in Practice'}`;
      }

      return result;
    }

    return name;
  };

  const setTeam = (side, team = null) => {
    if (side !== null) {
      setFieldValue(`team${side}`, team ? team.id : '');
    }
    setTeamSelector(null);
    setFooterDisabled(false);
  };

  const getLogo = (side) => {
    const selectedTeamId = values[`team${side}`];
    let logo = '';

    if (selectedTeamId) {
      logo =
        teams.byId[selectedTeamId].logos &&
        JSON.parse(teams.byId[selectedTeamId].logos).metaData.filename;
    }

    return logo ? (
      <img
        className="img-thumbnail"
        src={`${MEDIAURL}${logo}`}
        alt={`${teams.byId[selectedTeamId].name} Logo`}
      />
    ) : (
      '?'
    );
  };

  const goTeamSelector = (side) => {
    setTeamSelector(side);
    setFooterDisabled(true);
  };

  const teamWidget = (side, logo) => {
    return (
      <Col>
        <label>Team {side}</label>
        <Button
          variant={logo === '?' ? 'light' : 'outline-light'}
          disabled={(setup === SOLO && side === 'B') || isCreating}
          className="teamSelect"
          onClick={() => goTeamSelector(side)}
          style={{ fontSize: logo === '?' ? '5rem' : null }}
        >
          {logo}
        </Button>
      </Col>
    );
  };

  const setupBody = () => {
    const logos = {
      A: getLogo('A'),
      B: getLogo('B'),
      C: getLogo('C'),
      D: getLogo('D'),
      E: getLogo('E'),
      F: getLogo('F'),
      G: getLogo('G'),
      H: getLogo('H'),
    };

    return (
      <>
        {!alert.clear ? (
          <Row>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        <Row>
          <div
            className={[
              'setupFormLine',
              'teamSelection',
              setup === MULTI ? `multi ${gender.toLowerCase()}` : null,
            ].join(' ')}
          >
            {teamWidget('A', logos['A'])}
            {setup !== MULTI ? (
              <Col className="vsCol">
                <label>&nbsp;</label>
                <div
                  className={['vs', setup === SOLO ? 'disabled' : null].join(
                    ' '
                  )}
                >
                  <span className="vBlue">V</span>
                  <span className="vGray">S</span>
                </div>
              </Col>
            ) : null}
            {teamWidget('B', logos['B'])}
            {setup === MULTI ? teamWidget('C', logos['C']) : null}
            {setup === MULTI ? teamWidget('D', logos['D']) : null}
            {setup === MULTI // && gender === MALE
              ? teamWidget('E', logos['E'])
              : null}
            {setup === MULTI // && gender === MALE
              ? teamWidget('F', logos['F'])
              : null}
            {setup === MULTI // && gender === MALE
              ? teamWidget('G', logos['G'])
              : null}
            {setup === MULTI // && gender === MALE
              ? teamWidget('H', logos['H'])
              : null}
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>When</label>
              <Form.Check
                type="switch"
                label="Now"
                id="now"
                checked={values.now}
                onChange={handleChange}
                disabled={isCreating}
              />
            </Col>
            <Col>
              <label>Date / Time</label>
              <DatePicker
                name="startAt"
                disabled={values.now || isCreating}
                selected={values.startAt}
                onChange={(value) => setFieldValue('startAt', value)}
                showTimeSelect
                minDate={new Date()}
                dateFormat="MM/dd/yyyy h:mm aa"
                popperPlacement="auto"
              />
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>Format</label>
              <Form.Check
                type="switch"
                label="Competition"
                id="competition"
                checked={values.competition}
                onChange={handleChange}
                disabled={isCreating}
              />
            </Col>
            <Col>
              <label>Scoring</label>
              <Form.Control
                as="select"
                label="Required"
                id="teamScoring"
                value={prettyTeamScoring(values.teamScoring)}
                onChange={(e) =>
                  setFieldValue(
                    'teamScoring',
                    teamScoreType[
                      Object.keys(teamScoreType)[e.target.selectedIndex - 1]
                    ]
                  )
                }
                disabled={isCreating}
              >
                <option value="Select..." disabled hidden>
                  Select...
                </option>
                {Object.values(teamScoreType).map((type, i) => {
                  return <option key={i}>{prettyTeamScoring(type)}</option>;
                })}
              </Form.Control>
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>{`Apparatus (${values.apparatus.length}/${abbv.length})`}</label>
              <ToggleButtonGroup
                name="apparatus"
                className="apparatusToggle"
                type="checkbox"
                value={values.apparatus}
                onChange={(value) => {
                  if (value.length > 0) {
                    setFieldValue('apparatus', value);
                  }
                }}
              >
                {abbv.map((a, i) => {
                  return (
                    <ToggleButton
                      variant="outline-primary"
                      className="vCenter"
                      value={i}
                      key={i}
                      disabled={isCreating}
                    >
                      {a}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>Judging</label>
              <Form.Check
                type="switch"
                label="Enabled"
                id="judging"
                onChange={handleChange}
                checked={values.judging}
                disabled={isCreating}
              />
            </Col>
            <Col>
              <label>Alternating</label>
              <Form.Check
                type="switch"
                label="Enabled"
                id="alternating"
                onChange={handleChange}
                checked={values.alternating}
                disabled={isCreating}
              />
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>Session Name</label>
              <input
                name="name"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={calcSessionName()}
                disabled={isCreating}
              />
              <div className="codeSpecs">Autogenerated or custom name.</div>
            </Col>
          </div>
        </Row>
      </>
    );
  };

  const setupFooter = () => {
    return (
      <>
        <Row>
          <Button
            variant="outline-primary"
            className="createButton"
            onClick={handleSubmit}
            disabled={isCreating || footerDisabled}
          >
            {isCreating ? (
              <>
                {'Creating session... '}
                <Spinner
                  variant="primary"
                  role="status"
                  animation="border"
                  size="sm"
                />
              </>
            ) : (
              'Create session'
            )}
          </Button>
        </Row>
        <Row>
          <div className="modalLink">
            <span>Back to</span>
            <button
              type="button"
              className="linkButton"
              disabled={isCreating || footerDisabled}
              onClick={() => reset()}
            >
              select session
            </button>
          </div>
        </Row>
      </>
    );
  };

  const headerMsg = () => {
    const solo = (
      <>
        <span className="vBlue">S</span>
        <span className="vGray">olo</span>
      </>
    );

    const dual = (
      <>
        <span className="vBlue">D</span>
        <span className="vGray">ual</span>
      </>
    );

    const multi = (
      <>
        <span className="vBlue">M</span>
        <span className="vGray">ulti</span>
      </>
    );

    const mens = (
      <>
        <span className="vBlue">M</span>
        <span className="vGray">ens</span>
      </>
    );

    const womens = (
      <>
        <span className="vBlue">W</span>
        <span className="vGray">omens</span>
      </>
    );

    const sessionLabel = () => {
      if (setup === SOLO) {
        return solo;
      }
      if (setup === DUAL) {
        return dual;
      }
      if (setup === MULTI) {
        return multi;
      }
    };

    const genderLabel = () => {
      if (gender === MALE) {
        return mens;
      }

      if (gender === FEMALE) {
        return womens;
      }
    };

    return (
      <>
        {genderLabel()}
        <span>&nbsp;</span>
        {sessionLabel()}
        <span>&nbsp;session setup</span>
      </>
    );
  };

  return (
    <>
      <Modal.Header className="createOrJoinHeader">{headerMsg()}</Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body className="createOrJoinForm">
          {setupBody()}
          {teamSelector ? (
            <TeamSelector
              side={teamSelector}
              setTeam={setTeam}
              values={values}
              isMulti={setup === MULTI}
              setTeams={setTeams}
              teams={teams}
            />
          ) : null}
        </Modal.Body>
        <Modal.Footer className="createOrJoinForm">
          {setupFooter()}
        </Modal.Footer>
      </Form>
    </>
  );
}

export default SessionForm;
