import { useState, useEffect, useContext, createContext } from 'react';
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  ButtonGroup,
  Dropdown,
} from 'react-bootstrap';
import { useHotkeys } from 'react-hotkeys-hook';
import { useQuery, NetworkStatus } from '@apollo/client';
import { MEDIAURL } from '../../utilities/constants';
import { emptyTeam, emptyLineup } from '../../redux/_constants';
import { mApparatusAbbv, wApparatusAbbv } from '../../utilities/constants';
import { useSelector, useDispatch } from 'react-redux';
import producerStyles from './producer.module.css';
import {
  isMWApparatus,
  isLetter,
  alphaVal,
  numToApparatus,
  abbvToApparatusName,
  GYMapparatusAbbv,
  teamBrand,
} from '../../utilities/conversions';
import {
  SessionSubscriptionManager,
  OverlaySubscriptions,
  useTeamsByAthleteId,
  useRotations,
  useRotationsByLineup,
  useExperimental,
} from './hooks';
import GetFullSession from '../../apollo/queries/GetFullSession.graphql';
import GetOverlays from '../../apollo/queries/GetOverlays.graphql';
import { useApolloClient } from '@apollo/client';
import { createOverlay, useErrorHandler } from '../../apollo/helpers';
import CompetitorList from './competitorlist';
// import RoundCount from './roundcount';
import RoundSummary from './roundsummary';
import { CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../utilities/query';
import { GenderType, SessionTeamScoreType } from '../../models';
import { producerActions } from '../../redux/_actions';
import * as Icons from '../helpers/icons';
import { heroLogo } from '../helpers/logos';
import './overlay.css';
import { RoutineStatus } from '../../models';
import { CenterTimer } from '../helpers/timer';
import { ScoreboardTeamsDisplay } from './scoreboarddisplay';
import { TeamBannerLarge } from './teambanner';

export const SessionContext = createContext(null);

const overlayPath = '/media/overlays/';
const overlayOptions = {
  EVENT_LINEUP_SLAB_V3: 'EVENT-LINEUP-SLAB-v3',
  EVENT_LINEUP_SLAB_V3_DUAL: 'EVENT-LINEUP-SLAB-v3-DUAL',
  SCOREBUG: 'SCOREBUG',
  SCOREBUG_ROTATION: 'SCOREBUG-ROTATION',
  VIRTIUS_LOGO: 'VIRTIUS-LOGO',
  HEADER: 'VIRTIUS-HEADER',
  HEADER_ROTATION: 'VIRTIUS-HEADER-ROTATION',
  HEADER_FOOTER: 'HEADER-FOOTER',
  FOOTER_SCORES: 'FOOTER-SCORES',
  TIMER_TRANSITION: 'TIMER-TRANSITION',
  TIMER_TOUCH: 'TIMER-TOUCH',
  TIMER_WARMUP: 'TIMER-WARMUP',
  EVENT_LINEUP_SLAB_INDIVIDUAL: 'EVENT-LINEUP-SLAB-INDIVIDUAL',
  L3: 'L3-SILVER',

  /*
  FS_SLAB: 'FS-SLAB',
  L3_ECAC: 'L3_ECAC',
  BIO_SLAB: 'BIO-SLAB',
  EVENT_LINEUP_SLAB: 'EVENT-LINEUP-SLAB',
  FLASH_SCORE: 'FLASH-SCORE',
  
  FS_SLAB_ECAC: 'FS-SLAB_ECAC',
  VIDEO_BOX: 'VIDEO-BOX',
  SLICK_ECAC: 'SLICK_ECAC',
  DBOX: 'DBOX',
  DBOX_VERT: 'DBOX-VERT',
  QUAD: 'QUAD',
  QUAD_ECAC: 'QUAD_ECAC',
  BUG: 'BUG',
  */
};

const overlayConfig = {
  VIRTIUS_LOGO: {
    background: false,
    name: 'Virtius Logo',
  },
  TIMER_TRANSITION: {
    background: false,
    name: 'Transition Timer',
  },
  TIMER_TOUCH: {
    background: false,
    name: 'Touch Timer',
  },
  TIMER_WARMUP: {
    background: false,
    name: 'Warmup Timer',
  },
  SCOREBUG: {
    background: false,
    name: 'Scorebug',
  },
  SCOREBUG_ROTATION: {
    background: false,
    name: 'Scorebug w/ Rotation',
  },
  HEADER: {
    background: false,
    name: 'Header (Title & Tag)',
  },
  HEADER_ROTATION: {
    background: false,
    name: 'Header w/ Rotation',
  },
  FOOTER_SCORES: {
    background: false,
    name: 'Footer w/ Team Scores',
  },
  HEADER_FOOTER: {
    background: false,
    name: 'Header + Footer',
  },
  L3: {
    background: true,
    name: 'Lower Third',
  },
  EVENT_LINEUP_SLAB_V2: {
    background: true,
  },
  EVENT_LINEUP_SLAB_V3: {
    background: false,
    name: 'Single Lineup Slab',
  },
  EVENT_LINEUP_SLAB_V3_DUAL: {
    background: false,
    name: 'Dual Lineup Slab',
  },
  EVENT_LINEUP_SLAB_INDIVIDUAL: {
    background: true,
    name: 'Individual Competitor Slab',
  },
};

function L3({ athlete, team, title, altTeam, apparatus }) {
  const { L3 } = producerStyles;
  const l3Team = altTeam ? altTeam : team;

  const logo = `${MEDIAURL}${
    l3Team?.logo ||
    (l3Team?.logos && JSON.parse(l3Team.logos)?.metaData.filename) ||
    emptyTeam.logo
  }`;

  return (
    <div className={L3}>
      <Col className={producerStyles.text}>
        <div className={producerStyles.title}>
          {title}
          {apparatus ? ` » ${apparatus.toUpperCase()}` : ''}
        </div>
        <div className={producerStyles.main}>{athlete?.name}</div>
        <div className={producerStyles.sub}>{l3Team?.name}</div>
      </Col>
      <Col className={producerStyles.logo}>
        <Image src={logo} />
      </Col>
    </div>
  );
}

function Header({ title, showRotation, round, showPowered }) {
  const tag = (
    <Image
      src={`${overlayPath}Virtius-POWERED-TAG.png`}
      className={producerStyles.poweredTag}
    />
  );

  // let title = 'Bowling Green vs Central Michigan, UW-Oshkosh';
  // let title = 'Bowling Green vs Central Michigan, UW';
  // let title = 'Bowling Green vs Central Michigan';

  const titleScaleFactor = () => {
    // Note 45 characters is near the max
    const length = title?.length ?? 0;
    let factor = 1;

    if (length <= 25) {
      factor = 1;
    } else if (length <= 35) {
      factor = 0.85;
    } else if (length <= 45) {
      factor = 0.65;
    } else if (length > 55) {
      factor = 0.5;
    }

    return factor;
  };

  const titleFactor = titleScaleFactor();

  return (
    <div className={producerStyles.scoreStreamHeader}>
      <Row>
        <Col xs={9}>
          <span
            style={{
              fontSize: titleFactor !== 1 ? `${4 * titleFactor}vw` : null,
            }}
            className={producerStyles.scoreStreamTitle}
          >
            {title}
          </span>
        </Col>
        <Col xs={3}>{showPowered ? tag : null}</Col>
      </Row>
      <Row>
        <Col>
          {' '}
          <span className={producerStyles.scoreStreamRotation}>
            {showRotation ? `Rotation ${round}` : null}
          </span>
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
}

function FooterTeamScores({}) {
  return (
    <div className={producerStyles.scoreStreamFooter}>
      <Row className={producerStyles.scoreStreamFooterTeamScores}>
        <TeamBannerLarge />
      </Row>
    </div>
  );
}

function LineupSlab({ team, title, round, lineup, showSummary, apparatus }) {
  const { LineupSlab } = producerStyles;
  const customTeam = {
    ...team,
    lineup,
  };

  return (
    <div className={LineupSlab}>
      <Row className={producerStyles.slabHeader}>
        <Col className={producerStyles.text}>
          <div className={producerStyles.title}>{title}</div>
        </Col>
        <Col className={producerStyles.headerLogo}>
          {team?.logo && showSummary ? (
            <Image src={`${MEDIAURL}${team?.logo}`} />
          ) : (
            Icons?.[`${apparatus}noText`]
          )}
        </Col>
      </Row>
      <Row className={[producerStyles.lineup, 'left'].join(' ')}>
        <CompetitorList left={true} team={customTeam} customRound={round} />
        {showSummary ? (
          <RoundSummary
            left={true}
            team={customTeam}
            round={round}
            showName={false}
          />
        ) : null}
      </Row>
    </div>
  );
}

function LineupSlabV3({ dual = false, offsetX = 0, offsetY = 0 }) {
  const { activeLineup, activeApparatus, session } = useContext(SessionContext);
  const { LineupSlabV3, headerLogo, slabHeader, l6, l7, l8 } = producerStyles;
  const apparatusesInOrder = GYMapparatusAbbv(session.gender);

  const activeApparatusVal =
    dual && activeApparatus && activeApparatus?.length > 1
      ? activeApparatus?.[1]
      : activeApparatus?.[0];

  const activeLineupVal = activeLineup?.[dual ? 1 : 0] || null;

  const rotationLineup =
    (activeLineupVal?.lineupData &&
      JSON.parse(activeLineupVal?.lineupData)?.[
        apparatusesInOrder.indexOf(activeApparatusVal) + 1
      ]) ||
    JSON.parse(JSON.stringify(emptyLineup));
  const customTeam = {
    lineup: rotationLineup.slice(0, 8),
    lineupId: activeLineupVal?.id,
  };

  const teamDetails = activeLineupVal && teamBrand(activeLineupVal.team);
  const lineupLength = rotationLineup.length;
  let liHeight = l6;

  if (lineupLength === 7) {
    liHeight = l7;
  }

  if (lineupLength === 8) {
    liHeight = l8;
  }

  // console.log(activeLineup);
  // console.log(team);
  // console.log(teamDetails);
  // console.log(rotationLineup);
  // console.log(dual);
  // console.log(activeApparatus);
  // console.log(activeLineup);
  // console.log(activeLineupVal);
  // console.log(activeApparatusVal);
  // console.log(rotationLineup);

  return (
    !!activeApparatusVal &&
    !!activeLineupVal && (
      <CSSTransition
        in={!!activeApparatus && !!activeLineupVal}
        timeout={300}
        classNames="overlay"
        unmountOnExit
        key={`${activeApparatusVal}_${activeLineupVal}`}
      >
        <div
          className={LineupSlabV3}
          style={{ top: `${offsetY}%`, right: `${offsetX}%` }}
        >
          <Row
            className={slabHeader}
            style={{ background: teamDetails ? teamDetails.colors[0] : null }}
          >
            <Col className={producerStyles.text}>
              <div className={producerStyles.title}>
                {teamDetails?.name || ''}
              </div>
            </Col>
            <Col className={headerLogo}>
              {teamDetails?.logo ? (
                <Image src={teamDetails && `${MEDIAURL}${teamDetails?.logo}`} />
              ) : (
                Icons?.[`${activeApparatusVal}noText`]
              )}
            </Col>
          </Row>
          <Row className={[producerStyles.lineup, 'left', liHeight].join(' ')}>
            {activeLineupVal && (
              <CompetitorList
                left={true}
                team={customTeam}
                customRound={apparatusesInOrder.indexOf(activeApparatusVal) + 1}
                showHeadshot={false}
              />
            )}
            {activeApparatusVal && (
              <RoundSummary
                left={true}
                team={customTeam}
                round={apparatusesInOrder.indexOf(activeApparatusVal) + 1}
                showName={false}
              />
            )}
          </Row>
        </div>
      </CSSTransition>
    )
  );
}

function Scorebug({ sessionId, show = true, offsetX = 0, offsetY = 0 }) {
  return (
    show && (
      <div
        className={producerStyles.scoreboardTeams}
        style={{ bottom: `${offsetY}%`, right: `${offsetX}%` }}
      >
        <ScoreboardTeamsDisplay
          sessionId={sessionId}
          className="producerMode"
          isDarkMode={false}
        />
      </div>
    )
  );
}

function RotationTitle({ sessionId, show = true, offsetX = 0, offsetY = 0 }) {
  const round = useSelector((state) => state.producer.round);
  const rotations = useRotations(sessionId);
  const sessionApparatus = useSelector((state) => state.session.apparatus);
  const maxRound =
    Object.keys(rotations).length ||
    String(sessionApparatus).replace(/0+$/, '').length;

  return (
    show && (
      <div
        className={producerStyles.rotationTitle}
        style={{ bottom: `${offsetY}%`, right: `${offsetX}%` }}
      >
        <h4>
          Rotation {round} of {maxRound}
        </h4>
      </div>
    )
  );
}

function VirtiusLogo({ show = true, offsetX = 0, offsetY = 0 }) {
  return (
    show && (
      <div
        className={producerStyles.virtiusLogo}
        style={{ bottom: `${offsetY}%`, left: `${offsetX}%` }}
      >
        {heroLogo}
      </div>
    )
  );
}

function LineupSlabIndividual({
  team,
  title,
  round,
  lineup,
  showSummary,
  apparatus,
}) {
  const { LineupSlab, Individual } = producerStyles;
  const customTeam = {
    ...team,
    lineup,
  };

  return (
    <div className={[LineupSlab, Individual].join(' ')}>
      <Row className={producerStyles.slabHeaderIndividual}>
        <Col className={producerStyles.text}>
          <div className={producerStyles.title}>{title}</div>
        </Col>
        <Col className={producerStyles.headerLogo}>
          {team?.logo && showSummary ? (
            <Image src={`${MEDIAURL}${team?.logo}`} />
          ) : (
            Icons?.[`${apparatus}noText`]
          )}
        </Col>
      </Row>
      <Row className={[producerStyles.lineupIndividual, 'left'].join(' ')}>
        <CompetitorList left={true} team={customTeam} customRound={round} />
        {showSummary ? (
          <RoundSummary
            left={true}
            team={team}
            round={round}
            showName={false}
          />
        ) : null}
      </Row>
    </div>
  );
}

function selectOverlay(props) {
  switch (props?.type) {
    case 'L3':
      return (
        <L3
          {...props}
          apparatus={
            abbvToApparatusName[
              props?.routine?.apparatus ||
                numToApparatus(props?.round, true, props?.gender)
            ]
          }
        />
      );
    case 'VIRTIUS_LOGO':
      return <VirtiusLogo show={props.showLogo} offsetX={5} offsetY={5} />;
    case 'L3_ECAC':
      return <L3 athlete={props?.athlete} team={props?.team} title={' '} />;
    case 'TIMER_TRANSITION':
      return <CenterTimer start={120000} title="TRANSITION" />;
    case 'TIMER_TOUCH':
      return <CenterTimer start={240000} title="TOUCH" />;
    case 'TIMER_WARMUP':
      return <CenterTimer start={1200000} title="WARM-UP" />;
    case 'SCOREBUG':
      return (
        <Scorebug
          sessionId={props.sessionId}
          show={props.showScorebug}
          offsetX={5}
          offsetY={5}
        />
      );
    case 'SCOREBUG_ROTATION':
      return (
        <>
          <RotationTitle
            sessionId={props.sessionId}
            show={props.showScorebug}
            offsetX={5}
            offsetY={20}
          />
          <Scorebug
            sessionId={props.sessionId}
            show={props.showScorebug}
            offsetX={5}
            offsetY={5}
          />
        </>
      );
    case 'HEADER':
      return (
        <Header
          title={props?.title}
          showRotation={false}
          round={props?.round}
          showPowered={true}
        />
      );
    case 'HEADER_ROTATION':
      return (
        <Header
          title={props?.title}
          showRotation={true}
          round={props?.round}
          showPowered={true}
        />
      );
    case 'HEADER_FOOTER':
      return (
        <>
          <Header
            title={props?.title}
            showRotation={true}
            round={props?.round}
            showPowered={true}
          />
          <FooterTeamScores />
        </>
      );
    case 'FOOTER_SCORES':
      return <FooterTeamScores />;
    case 'EVENT_LINEUP_SLAB_V2':
      return (
        <LineupSlab
          title={props?.team?.name || 'Lineup'}
          team={props?.team}
          lineup={props?.lineup?.[props?.routine?.rotation]}
          round={props?.routine?.rotation}
          showSummary={props?.showSummary}
          apparatus={props?.routine?.apparatus}
        />
      );
    case 'EVENT_LINEUP_SLAB_V3':
      return (
        <>
          <RotationTitle
            sessionId={props.sessionId}
            show={props.showScorebug}
            offsetX={5}
            offsetY={20}
          />
          <LineupSlabV3 offsetX={5} offsetY={35} />
          <Scorebug
            sessionId={props.sessionId}
            show={props.showScorebug}
            offsetX={5}
            offsetY={5}
          />
          <VirtiusLogo show={props.showLogo} offsetX={5} offsetY={5} />;
        </>
      );
    case 'EVENT_LINEUP_SLAB_V3_DUAL':
      return (
        <>
          <LineupSlabV3 />
          <LineupSlabV3 offsetY={40} dual={true} />
          <RotationTitle
            sessionId={props.sessionId}
            show={props.showScorebug}
            offsetX={0}
            offsetY={15}
          />
          <Scorebug sessionId={props.sessionId} show={props.showScorebug} />
          <VirtiusLogo show={props.showLogo} offsetX={5} offsetY={5} />
        </>
      );
    case 'EVENT_LINEUP_SLAB_INDIVIDUAL':
      return (
        <LineupSlabIndividual
          title={
            abbvToApparatusName[
              props?.routine?.apparatus ||
                numToApparatus(props?.round, true, props?.gender)
            ]
          }
          team={props?.team}
          lineup={props?.lineup?.[props?.routine?.rotation || props?.round]}
          round={props?.routine?.rotation || props?.round}
          showSummary={props?.showSummary}
          apparatus={
            props?.routine?.apparatus ||
            numToApparatus(props?.round, true, props?.gender)
          }
        />
      );
    default:
      return null;
  }
}

function ProducerSelector({ setOverlayIndex, overlayIndex }) {
  const currentOverlay =
    overlayConfig[Object.keys(overlayOptions)[overlayIndex]].name;

  return (
    <Row>
      <Col>
        <h3>Overlays: </h3>
        <Dropdown drop="down" key="down">
          <Dropdown.Toggle
            variant="light"
            id="dropdown-overlay"
            style={{ width: '100%', textAlign: 'left' }}
          >
            {currentOverlay}
          </Dropdown.Toggle>
          <Dropdown.Menu flip={false}>
            {Object.keys(overlayOptions).map((o, i) => {
              return (
                <Dropdown.Item
                  key={i}
                  onClick={(e) => {
                    setOverlayIndex(i);
                  }}
                >
                  {overlayConfig[o].name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}

function ProducerMenu({
  sessionId,
  overlay,
  setShowScorebug,
  showScorebug,
  setShowLogo,
  showLogo,
  sync,
  overlaySync,
}) {
  const {
    activeApparatus,
    setActiveApparatus,
    activeLineup,
    setActiveLineup,
    session,
  } = useContext(SessionContext);
  const rotations = useRotations(sessionId);
  const stages = useRotationsByLineup(sessionId);
  const dispatch = useDispatch();
  const round = useSelector((state) => state.producer.round);
  const sessionApparatus = useSelector((state) => state.session.apparatus);
  const apparatusesInOrder = GYMapparatusAbbv(session.gender);
  const maxRound =
    Object.keys(rotations).length ||
    String(sessionApparatus).replace(/0+$/, '').length;
  const hotKeyOptions = [...Array(maxRound).keys()].map((x) => x + 1).join();
  const { EVENT_LINEUP_SLAB_V3_DUAL } = overlayOptions;

  // console.log(stages);
  // console.log(overlay);
  // console.log(hotKeyOptions);

  useHotkeys(
    hotKeyOptions,
    (e) => {
      e.preventDefault();
      handleChangeRound(e, Number.parseInt(e.key));
    },
    [round, maxRound, activeApparatus, activeLineup]
  );

  useEffect(() => {
    if (overlaySync?.command) {
      const command = JSON.parse(overlaySync?.command);

      if (command.round && command.round !== round) {
        handleChangeRound(null, command.round);
      }
    }
  }, [overlaySync]);

  if (!stages || !rotations) {
    return;
  }

  const handleScorebug = () => {
    setShowScorebug(!showScorebug);
  };

  const handleLogo = () => {
    setShowLogo(!showLogo);
  };

  const handleChangeRound = (e, newRound) => {
    if (Object.keys(stages).length === 0) {
      return;
    }

    const flattenedLineups =
      (activeApparatus &&
        activeApparatus.map((a) => stages?.[newRound]?.[a]).flat()) ||
      null;

    const flattenedApparatus =
      Object.keys(stages?.[newRound]).filter(
        (apparatus) => activeApparatus && activeApparatus.includes(apparatus)
      ) || null;

    setActiveApparatus(flattenedApparatus);
    setActiveLineup(flattenedLineups);

    dispatch(producerActions.changeRound(newRound));
  };

  const handleSelectStage = (stage) => {
    let newActiveApparatus = [];

    if (Array.isArray(activeApparatus)) {
      const index = activeApparatus.indexOf(stage);
      if (index !== -1) {
        newActiveApparatus = [...activeApparatus];
        newActiveApparatus.splice(index, 1);
      } else {
        if (overlay === EVENT_LINEUP_SLAB_V3_DUAL) {
          newActiveApparatus = [...activeApparatus, stage];
        } else {
          newActiveApparatus.push(stage);
        }
      }
    } else {
      newActiveApparatus.push(stage);
    }

    const flattened = newActiveApparatus
      .map((a) => stages?.[round]?.[a])
      .flat();

    setActiveLineup(flattened);
    setActiveApparatus(newActiveApparatus);
  };

  const handleSelectSquad = (squad) => {
    let newActiveLineup = [];
    let newActiveApparatus = [];

    if (Array.isArray(activeLineup)) {
      const index = activeLineup.findIndex((l) => l.id === squad.id);

      if (index !== -1) {
        activeLineup.forEach((lu, i) => {
          if (i !== index) {
            newActiveLineup.push(lu);
          }
        });
      } else {
        if (overlay === EVENT_LINEUP_SLAB_V3_DUAL) {
          newActiveLineup = [...activeLineup, squad];
        } else {
          newActiveLineup.push(squad);
        }
      }
    } else {
      newActiveLineup.push(squad);
    }

    const newActiveLineupIds = newActiveLineup.map((lu) => lu.id);
    Object.keys(stages?.[round]).forEach((apparatus) => {
      const index = stages?.[round][apparatus].findIndex((l) =>
        newActiveLineupIds.includes(l.id)
      );
      if (index !== -1) {
        newActiveApparatus.push(apparatus);
      }
    });

    setActiveLineup(newActiveLineup);
    setActiveApparatus(newActiveApparatus);
  };

  return (
    <Row>
      {/*<Col>
        <h4>Slab 1</h4>
      </Col>*/}
      <Col>
        <h3>Rotation: </h3>
        <ButtonGroup>
          {Object.keys(rotations).length > 0 &&
            Object.values(rotations).map((r) => {
              const selectedRound = r.order + 1;
              // console.log(selectedRound);
              return (
                <Button
                  onClick={(e) => handleChangeRound(e, selectedRound)}
                  variant="secondary"
                  disabled={round === selectedRound}
                  key={r.id}
                >
                  {r.order + 1}
                </Button>
              );
            })}
        </ButtonGroup>
      </Col>
      <Col>
        <h3>Stage: </h3>
        <ButtonGroup>
          {Object.keys(stages).length > 0 &&
            Object.keys(stages?.[round])
              .sort(
                (a, b) =>
                  apparatusesInOrder.indexOf(a) - apparatusesInOrder.indexOf(b)
              )
              .map((s) => {
                // console.log(s);
                // console.log(activeApparatus);
                //console.log(activeApparatus && activeApparatus.fins([s]));
                return (
                  <Button
                    onClick={() => handleSelectStage(s)}
                    variant="secondary"
                    className={
                      activeApparatus && activeApparatus.includes(s)
                        ? producerStyles.selected
                        : null
                    }
                    key={s}
                  >
                    {s}
                  </Button>
                );
              })}
        </ButtonGroup>
      </Col>
      <Col xs={3}>
        <h3>Squad: </h3>
        <ButtonGroup className="squadButtons" style={{ display: 'flow-root' }}>
          {stages[round] &&
            Object.values(stages[round])
              ?.flat(Infinity)
              .sort((a, b) => {
                const { name: nameA } = teamBrand(a.team);
                const { name: nameB } = teamBrand(b.team);
                const nameAupper = nameA.toUpperCase(); // ignore upper and lowercase
                const nameBupper = nameB.toUpperCase(); // ignore upper and lowercase
                if (nameAupper < nameBupper) {
                  return -1;
                }
                if (nameAupper > nameBupper) {
                  return 1;
                }
                return 0;
              })
              .map((s) => {
                if (!s) {
                  return null;
                }

                const teamDetails = s?.team && teamBrand(s?.team);

                return (
                  <Button
                    onClick={(e) => handleSelectSquad(s)}
                    variant="secondary"
                    className={
                      activeLineup && activeLineup.find((l) => l?.id === s?.id)
                        ? producerStyles.selected
                        : null
                    }
                    key={s.id}
                  >
                    <span>{teamDetails.name}</span>
                  </Button>
                );
              })}
        </ButtonGroup>
      </Col>
      {/*<Col>
        <h4>Slab 2</h4>
      </Col>*/}
      <Col xs={2}>
        <h3>Settings:</h3>
        <Button
          variant="secondary"
          onClick={handleLogo}
          className={[
            producerStyles.settingButton,
            showLogo ? producerStyles.selected : null,
          ].join(' ')}
        >
          Logo
        </Button>
        <Button
          variant="secondary"
          onClick={handleScorebug}
          className={[
            producerStyles.settingButton,
            showScorebug ? producerStyles.selected : null,
          ].join(' ')}
        >
          Scorebug
        </Button>
      </Col>
      <Col xs={4}>
        <h3>Commands:</h3>
        <Button variant="secondary" onClick={sync}>
          Sync
        </Button>
        <span style={{ padding: '1vw', fontSize: '1vw' }}>
          Overlay:{' '}
          {overlaySync?.command &&
            JSON.parse(overlaySync?.command).overlayIndex}
        </span>
        <span style={{ padding: '1vw', fontSize: '1vw' }}>
          Rotation:{' '}
          {overlaySync?.command && JSON.parse(overlaySync?.command).round}
        </span>
        <span style={{ padding: '1vw', fontSize: '1vw' }}>
          ID: {overlaySync?.command && JSON.parse(overlaySync?.command).id}
        </span>
      </Col>
      <Col />
      <Col />
      <Col />
    </Row>
  );
}

function ProducerOverlays({ sessionId, loadType, isDarkMode }) {
  const teams = useSelector((state) => state.producer.teams);
  const userId = useSelector((state) => state.user.profile?.id);
  const gender = useSelector((state) => state.session.gender);
  const round = useSelector((state) => state.producer.round);
  const isTeam = useSelector(
    (state) => state.session.teamScoring !== SessionTeamScoreType.NONE
  );
  const [type, setType] = useState(
    loadType || numToApparatus(round, true, gender)
  );
  const [darkMode, setDarkMode] = useState(isDarkMode);
  const [overlayIndex, setOverlayIndex] = useState(0);
  const [routineIndex, setRoutineIndex] = useState(0);
  const [requestedCopy, setRequestedCopy] = useState(false);
  const apolloClient = useApolloClient();
  const history = useHistory();
  const queryParams = useQueryParams();
  const teamsByAthleteId = useTeamsByAthleteId(sessionId);
  const experimental = useExperimental({ sessionId });
  const [showScorebug, setShowScorebug] = useState(true);
  const [showLogo, setShowLogo] = useState(true);
  const [overlaySync, setOverlaySync] = useState(null);

  const { data, networkStatus } = useQuery(GetFullSession, {
    variables: { id: sessionId },
  });

  const { name: title } = data?.getSession;

  const { data: overlayData } = useQuery(GetOverlays, {
    variables: { sessionId },
    skip: !sessionId,
  });

  useEffect(() => {
    setType(numToApparatus(round, true, gender));
  }, [round]);

  useEffect(() => {
    document.documentElement.setAttribute(
      'data-theme',
      isDarkMode ? 'dark' : null
    );
  }, [isDarkMode]);

  useEffect(() => {
    const oSync =
      overlayData &&
      overlayData.SessionOverlays.items
        .slice()
        .sort((a, b) => new Date(b.displayAt) - new Date(a.displayAt));

    if (!oSync || !oSync.length) {
      return;
    }

    //console.log(oSync);
    setOverlaySync(oSync[0]);

    const syncCommand = oSync[0]?.command && JSON.parse(oSync[0].command);
    const newIndex = (!!syncCommand && syncCommand.overlayIndex) || 0;
    //console.log(syncCommand);
    //console.log(newIndex);
    // sync commands
    if (newIndex !== overlayIndex) {
      setOverlayIndex(newIndex);
    }
  }, [overlayData]);

  const handleCreateOverlayErrors = useErrorHandler(
    'There was a problem sending the overlay'
  );

  useEffect(() => {
    if (data && requestedCopy && networkStatus !== NetworkStatus.refetch) {
      setRequestedCopy(false);
    }
  }, [requestedCopy, networkStatus, data]);

  const lineups =
    data?.getSession?.lineups?.items
      ?.slice()
      .sort((a, b) => a.order - b.order) || [];
  const isApparatus = isMWApparatus(type);
  const isLineup = isLetter(type) && alphaVal(type) < lineups.length;
  const lineupId = isLineup && lineups[alphaVal(type)]?.id;
  let routines = [];
  let typeRoutines = [];

  lineups.forEach((l) => {
    const lineupRoutines = l?.routines?.items.filter(
      (r) => r.state !== RoutineStatus.DELETED
    );

    routines = [...routines, ...lineupRoutines];
  });

  if (isApparatus) {
    typeRoutines =
      routines &&
      routines.filter(
        (routine) =>
          routine.apparatus === type.toUpperCase() &&
          routine.status !== RoutineStatus.DELETED
      );
  } else if (isLineup) {
    typeRoutines =
      routines &&
      routines.filter(
        (routine) =>
          lineupId === routine.lineupId &&
          routine.status !== RoutineStatus.DELETED
      );
  } else {
    typeRoutines = routines.filter(
      (routine) => routine.status !== RoutineStatus.DELETED
    );
  }

  typeRoutines.sort(
    (a, b) =>
      new Date(b.completedAt || b.startedAt || b.createdAt) -
      new Date(a.completedAt || a.startedAt || a.createdAt)
  );

  const selectedRoutine = typeRoutines[routineIndex] || 0;
  let selectedTeam =
    teams?.find((t) => {
      return t?.lineupId === selectedRoutine?.lineupId;
    }) || teams[0];

  const selectedLineup =
    selectedTeam &&
    JSON.parse(lineups.find((l) => selectedTeam.lineupId === l.id)?.lineupData);
  const selectedAthlete =
    selectedRoutine?.athlete || selectedLineup?.[round]?.[0];
  const numOverlays = Object.values(overlayOptions).length;

  useHotkeys(
    'd',
    (e) => {
      e.preventDefault();
      document.documentElement.setAttribute(
        'data-theme',
        darkMode ? 'transparent' : 'dark'
      );
      setDarkMode(!darkMode);
      console.log('Dark Mode: ', !darkMode);
    },
    [darkMode]
  );

  useHotkeys(
    'left, shift+,',
    (e) => {
      e.preventDefault();

      if (overlayIndex > 0) {
        console.log(
          'Overlay: ',
          Object.values(overlayOptions)[overlayIndex - 1]
        );
        setOverlayIndex(overlayIndex - 1);
      }
    },
    [overlayIndex]
  );

  useHotkeys(
    'right, shift+.',
    (e) => {
      e.preventDefault();

      if (overlayIndex < numOverlays - 1) {
        console.log(
          'Overlay: ',
          Object.values(overlayOptions)[overlayIndex + 1]
        );
        setOverlayIndex(overlayIndex + 1);
      }
    },
    [overlayIndex]
  );

  useHotkeys(
    'down, .',
    (e) => {
      e.preventDefault();

      if (routineIndex > 0) {
        setRoutineIndex(routineIndex - 1);
      }
    },
    [routineIndex]
  );

  useHotkeys(
    'up, ,',
    (e) => {
      e.preventDefault();

      if (routineIndex < routines.length - 1) {
        setRoutineIndex(routineIndex + 1);
      }
    },
    [routineIndex]
  );

  useHotkeys(
    't',
    (e) => {
      e.preventDefault();
      let newType = type;

      if (!type) {
        return;
      }

      if (isApparatus) {
        const apparatusChoice =
          gender === GenderType.MALE ? mApparatusAbbv : wApparatusAbbv;
        const apparatusIndex = apparatusChoice.indexOf(type.toUpperCase());
        newType =
          apparatusIndex + 1 < apparatusChoice.length
            ? numToApparatus(apparatusIndex + 2, true, gender)
            : numToApparatus(1, true, gender);
      }

      if (isLineup) {
        newType =
          alphaVal(type) + 1 < teams.length
            ? String.fromCharCode(type.charCodeAt(0) + 1)
            : 'a';
      }

      queryParams.set('producer', newType);
      history.push({
        search: queryParams.toString(),
      });
      setType(newType);
    },
    [routineIndex, type]
  );

  useHotkeys(
    'enter',
    (e) => {
      e.preventDefault();
      handleSync();
    },
    []
  );

  const handleSync = () => {
    console.log('sync');
    const input = {
      command: JSON.stringify({
        //type: type,
        //routineIndex: routineIndex,
        //overlayIndex: overlayIndex,
        round: round,
        msg: "Let's go",
        //athlete: selectedAthlete,
        userId,
        id: overlayData.SessionOverlays.items.length || 0,
      }),
      sessionId,
      displayAt: new Date(),
    };

    createOverlay(apolloClient, input).catch((e) => {
      console.log(e);
      handleCreateOverlayErrors();
    });
  };

  const handleClick = (e) => {
    //setOverlayIndex(overlayIndex + 1 < numOverlays ? overlayIndex + 1 : 0);
  };

  const isReady =
    (selectedAthlete && selectedTeam && selectedRoutine) || !!selectedLineup;

  return (
    <SessionContext.Provider value={experimental}>
      <Container
        fluid
        className={producerStyles.container}
        onClick={handleClick}
      >
        {Object.keys(overlayOptions).map((o, i) => {
          return (
            <CSSTransition
              in={overlayIndex === i}
              timeout={300}
              classNames="overlay"
              unmountOnExit
              key={o}
            >
              <Row className={producerStyles.display}>
                {overlayConfig[o]?.background ? (
                  <Image
                    src={`${overlayPath}Virtius-${overlayOptions[o]}.png`}
                    className={producerStyles.overlayTemplate}
                  />
                ) : null}
                {isReady
                  ? selectOverlay({
                      type: o,
                      //title: selectedTeam.name || ",
                      title,
                      athlete: selectedAthlete,
                      altTeam:
                        teamsByAthleteId[
                          selectedAthlete?.id || selectedAthlete?.athleteId
                        ],
                      team: selectedTeam,
                      routine: selectedRoutine,
                      lineup: selectedLineup,
                      showSummary: isTeam,
                      round,
                      gender,
                      sessionId: sessionId,
                      showScorebug: showScorebug,
                      showLogo: showLogo,
                    })
                  : null}
              </Row>
            </CSSTransition>
          );
        })}

        <SessionSubscriptionManager sessionId={sessionId} />
        <OverlaySubscriptions sessionId={sessionId} />
      </Container>
      {/*<RoundCount />*/}
      <Container fluid className={producerStyles.menu}>
        <Row>
          <Col xs={2}>
            <ProducerSelector
              overlayIndex={overlayIndex}
              setOverlayIndex={setOverlayIndex}
            />
          </Col>
          <Col xs={10}>
            <ProducerMenu
              sessionId={sessionId}
              overlay={
                overlayOptions[Object.keys(overlayOptions)[overlayIndex]]
              }
              setShowScorebug={setShowScorebug}
              showScorebug={showScorebug}
              setShowLogo={setShowLogo}
              showLogo={showLogo}
              sync={handleSync}
              overlaySync={overlaySync}
            />
          </Col>
        </Row>
      </Container>
    </SessionContext.Provider>
  );
}

export default function Producer(props) {
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', 'transparent');
  }, []);

  /*
  if (props?.type?.toLowerCase() === 'teams') {
    return <ScoreboardTeamsDisplay {...props} />;
  }*/

  return <ProducerOverlays {...props} />;
}
