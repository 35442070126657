import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { uiState } from '../../redux/_constants';
import { menuIcon } from './icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useUser } from '../../user-provider';
import { useJudgingEnabled } from '../session/hooks';

function MenuBubble() {
  const { isAdmin } = useUser();
  const { sessionKey } = useSelector((state) => state.session);
  const { user } = useSelector((state) => state.user);
  const { ADMIN, SETUP, SESSION, START, CREATE, HOME } = uiState;
  const history = useHistory();
  const location = useLocation();
  const judgingEnabled = useJudgingEnabled();
  const isOfficialScoresheet = location.search.includes('officialscoresheet');
  const isScoreslips = location.search.includes('scoreslips');
  const isScoresheet =
    location.search.includes('scoresheet') && !isOfficialScoresheet;
  const isBeta = location.search.includes('beta');
  const isSession = [SESSION, SETUP].includes(location.pathname);

  const showAdminOption = () => {
    return isAdmin ? (
      <Dropdown.Item
        as="button"
        variant="outline"
        onSelect={() => history.push(ADMIN)}
      >
        Admin
      </Dropdown.Item>
    ) : null;
  };

  const showProducerOption = () => {
    return isAdmin && isSession ? (
      <Dropdown.Item
        as="button"
        variant="outline"
        onSelect={() => history.push(`session?s=${sessionKey}&producer`)}
      >
        Producer
      </Dropdown.Item>
    ) : null;
  };

  const showScoresheetOption = () => {
    return judgingEnabled && isSession && !isOfficialScoresheet ? (
      <Dropdown.Item
        as="button"
        variant="outline"
        onSelect={() =>
          history.push(
            `session?s=${sessionKey}&officialscoresheet`
            //`${SCORESHEET}?s=${sessionKey}`
          )
        }
      >
        Scoresheet
      </Dropdown.Item>
    ) : null;
  };

  const showScoreslipsOption = () => {
    return judgingEnabled && isSession && !isScoreslips ? (
      <Dropdown.Item
        as="button"
        variant="outline"
        onSelect={() => history.push(`session?s=${sessionKey}&scoreslips`)}
      >
        Scoreslips
      </Dropdown.Item>
    ) : null;
  };

  const showLineupsOption = () => {
    return judgingEnabled && isSession && !isScoresheet ? (
      <Dropdown.Item
        as="button"
        variant="outline"
        onSelect={() => history.push(`session?s=${sessionKey}&scoresheet`)}
      >
        Lineups
      </Dropdown.Item>
    ) : null;
  };

  const showBetaOption = () => {
    return judgingEnabled && !isBeta && isSession ? (
      <Dropdown.Item
        as="button"
        variant="outline"
        onSelect={() => history.push(`session?s=${sessionKey}&beta=admin`)}
      >
        Beta (New)
      </Dropdown.Item>
    ) : null;
  };

  const showSessionSetupOption = () => {
    return judgingEnabled && isAdmin && isSession ? (
      <Dropdown.Item
        as="button"
        variant="outline"
        onSelect={() => history.push(`session/${sessionKey}/setup`)}
      >
        Setup (Session)
      </Dropdown.Item>
    ) : null;
  };

  const showSessionOption = () => {
    return judgingEnabled && isSession ? (
      <Dropdown.Item
        as="button"
        variant="outline"
        onSelect={() => history.push(`session?s=${sessionKey}`)}
      >
        Session
      </Dropdown.Item>
    ) : null;
  };

  const showCreateOption = () => {
    return isAdmin ? (
      <Dropdown.Item
        as="button"
        variant="outline"
        onSelect={() => history.push(CREATE)}
      >
        Create
      </Dropdown.Item>
    ) : null;
  };

  const showSetupSessionOption = () => {
    return isAdmin ? (
      isSession ? (
        <Dropdown.Item as="button" variant="outline" onSelect={handleSetup}>
          Setup (Admin)
        </Dropdown.Item>
      ) : null
    ) : null;
  };

  const showHelpOption = () => {
    return judgingEnabled ? (
      isSession ? (
        <Dropdown.Item
          variant="outline"
          href="https://www.youtube.com/watch?v=EKBZ5QdT-z0"
        >
          Help (Video)
        </Dropdown.Item>
      ) : null
    ) : null;
  };

  const handleSetup = () => {
    let target = SETUP;
    let params = sessionKey ? `?s=${sessionKey}` : null;

    history.push(`${target}${params}`);
  };

  return (
    <Dropdown
      className="menuBubble"
      //onMouseEnter = { () => setShow(true) }
      //onMouseLeave = { () => setShow(false) }
    >
      <Dropdown.Toggle
        id="dropdown-menuBubble"
        className="vGray"
        variant="outline"
      >
        {menuIcon}
      </Dropdown.Toggle>
      <Dropdown.Menu className="menuBubble-menu">
        <Dropdown.Item
          as="button"
          variant="outline"
          onSelect={() => history.push(user ? START : HOME)}
        >
          Start
        </Dropdown.Item>
        {showAdminOption()}
        {showCreateOption()}
        {isSession && judgingEnabled ? <hr /> : null}
        {showSessionOption()}
        {showScoresheetOption()}
        {showScoreslipsOption()}
        {showLineupsOption()}
        {showHelpOption()}
        {isSession && isAdmin ? <hr /> : null}
        {showBetaOption()}
        {showSessionSetupOption()}
        {showSetupSessionOption()}
        {showProducerOption()}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default MenuBubble;
