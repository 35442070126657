import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useHotkeys } from 'react-hotkeys-hook';
import * as Icons from '../helpers/icons';
import { useQuery, NetworkStatus } from '@apollo/client';
import {
  MEDIAURL,
  SCOREBOARD_DWELL,
  SCOREBOARD_TIMEOUT,
  POLLING_INTERVAL,
} from '../../utilities/constants';
import { virtiusV } from '../helpers/logos';
import MatchCountdown from '../helpers/matchcountdown';
import { nCircle, sCircle } from '../helpers/icons';
import { useInterval } from '../../utilities/hooks';
import {
  convertMillipointsToDisplay,
  useRoutineScore,
  useTeamsByApparatusData,
} from '../../utilities/scoring';
import { emptyTeam } from '../../redux/_constants';
import { useSelector } from 'react-redux';
import './scoreboarddisplay.css';
import {
  fullNameToInitialLastName,
  isMWApparatus,
  isLetter,
  alphaVal,
  teamBrand,
  abbvToApparatusName,
  GYMapparatus,
} from '../../utilities/conversions';
import {
  SessionSubscriptionManager,
  useTeamsByAthleteId,
  useDisplayConfig,
  useExperimental,
  useEvalConfig,
} from './hooks';
import GetFullSession from '../../apollo/queries/GetFullSession.graphql';
import { RoutineStatus, JudgePanelType } from '../../models';
import { flatMap } from 'lodash';
import { useImage } from '../../utilities/media';
import { Keyframes } from '../../utilities/animation';
import { useQueryParams } from '../../utilities/query';

const SCOREBOARD_SIZE = {
  XS: 'XSMALL',
  SM: 'SMALL',
  MD: 'MEDIUM',
  LG: 'LARGE',
  XL: 'XLARGE',
};

function ScoreboardSoloDisplay({ sessionId, type, isDarkMode, size }) {
  const displayConfig = useDisplayConfig();
  const evalConfig = useEvalConfig();
  const queryParams = useQueryParams();
  const { scorePrecision } = displayConfig;
  const [index, setIndex] = useState(0);
  const [darkMode, setDarkMode] = useState(isDarkMode);
  const [showCursor, setShowCursor] = useState(true);
  const isApparatus = isMWApparatus(type);
  const isSmall = [SCOREBOARD_SIZE.XS, SCOREBOARD_SIZE.SM, 'SM', 'XS'].includes(
    size?.toUpperCase()
  );
  const isXSmall = [SCOREBOARD_SIZE.XS, 'XS'].includes(size?.toUpperCase());
  const themeType = queryParams.get('theme');
  const isM = themeType && themeType.toUpperCase() === 'M';
  const routineScore = useRoutineScore();
  const { data: sessionData } = useQuery(GetFullSession, {
    variables: { id: sessionId },
  });
  const [currentRoutine, setCurrentRoutine] = useState(null);
  const [shouldDwell, setShouldDwell] = useState(false);
  const [shouldTimeout, setShouldTimeout] = useState(false);
  const { parseImageFile: image } = useImage();
  const experimental = useExperimental({ sessionId });
  const { isMeanGirls } = experimental;
  const showStickBonus = !!evalConfig?.stickBonus;
  const filteredLineups = sessionData?.getSession?.lineups?.items
    ?.slice()
    .filter((l) => !l?._deleted);

  const routines = sessionData.getSession
    ? flatMap(filteredLineups.map((lineup) => lineup.routines.items))
    : [];

  const lineups = [...filteredLineups]?.sort((a, b) => a.order - b.order);
  const isLineup = isLetter(type) && alphaVal(type) < lineups.length;
  const lineupId = isLineup && lineups[alphaVal(type)]?.id;
  const teamsByAthleteId = useTeamsByAthleteId(sessionId);
  const is4digsClass = scorePrecision === 4 ? 'is4digs' : '';

  useEffect(() => {
    document.documentElement.setAttribute(
      'data-theme',
      isDarkMode ? 'dark' : null
    );
  }, [isDarkMode]);

  useEffect(() => {
    if (isM) {
      document.documentElement.setAttribute('data-theme', 'michigan');
    }
  }, [isM]);

  let typeRoutines = [];

  if (isApparatus) {
    typeRoutines =
      (routines &&
        routines
          .filter(
            (routine) =>
              routine.apparatus === type.toUpperCase() &&
              routine.status !== RoutineStatus.DELETED
          )
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))) ||
      [];
  }

  if (isLineup) {
    typeRoutines =
      (routines &&
        routines
          .filter(
            (routine) =>
              lineupId === routine.lineupId &&
              routine.status !== RoutineStatus.DELETED
          )
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))) ||
      [];
  }

  const completedRoutines = typeRoutines.filter(
    (routine) => routine.status === RoutineStatus.COMPLETE
  );

  const sortedRoutines = typeRoutines;

  useEffect(() => {
    const scoreDwellTime =
      Date.now() - new Date(completedRoutines[0]?.updatedAt)?.getTime();
    const shouldDwell = scoreDwellTime < SCOREBOARD_DWELL;
    const shouldTimeout =
      Date.now() - new Date(sortedRoutines[0]?.updatedAt)?.getTime() >
      SCOREBOARD_TIMEOUT;

    if (shouldDwell) {
      setCurrentRoutine(completedRoutines[0]);
      setShouldDwell(true);
      setShouldTimeout(false);
    } else if (shouldTimeout) {
      setCurrentRoutine(null);
      setShouldDwell(false);
      setShouldTimeout(true);
    } else {
      setCurrentRoutine(sortedRoutines[0]);
      setShouldDwell(false);
      setShouldTimeout(false);
    }
  }, [
    sortedRoutines[0]?.updatedAt,
    completedRoutines[0]?.updatedAt,
    setCurrentRoutine,
    setShouldDwell,
    setShouldTimeout,
  ]);

  useInterval(
    async () => {
      const deltaTimeout =
        Date.now() - new Date(completedRoutines[0]?.updatedAt)?.getTime();
      console.log(
        `Showing score: ${((SCOREBOARD_DWELL - deltaTimeout) / 1000).toFixed(
          0
        )}s`
      );
      if (deltaTimeout > SCOREBOARD_DWELL) {
        setCurrentRoutine(sortedRoutines[0]);
        setShouldDwell(false);
      }
    },
    POLLING_INTERVAL.SECOND,
    shouldDwell
  );

  useInterval(
    async () => {
      setShowCursor(false);
    },
    POLLING_INTERVAL.MINUTE,
    showCursor
  );

  const currentAthlete = currentRoutine?.athlete;
  const currentTeam =
    teamsByAthleteId[currentAthlete?.id] ?? currentRoutine?.lineup?.team;
  const currentScores = currentRoutine && routineScore(currentRoutine);
  const teamColorA =
    (currentTeam && JSON.parse(currentTeam?.colors)[0]) || emptyTeam.color;
  const teamColorB =
    (currentTeam && JSON.parse(currentTeam?.colors)[1]) || emptyTeam.color;
  const isOnAir = currentRoutine?.status === RoutineStatus.ON_AIR;
  const isOnEval = currentRoutine?.status === RoutineStatus.ON_EVAL;
  const isComplete = currentRoutine?.status === RoutineStatus.COMPLETE;
  const isOpenScoring = useSelector(
    (state) => state.session.judgePanel === JudgePanelType.OPEN
  );

  useInterval(
    async () => {
      const deltaTimeout =
        Date.now() - new Date(sortedRoutines[0]?.updatedAt)?.getTime();
      console.log(
        `Standby in: ${((SCOREBOARD_TIMEOUT - deltaTimeout) / 1000).toFixed(
          0
        )}s`
      );
      if (deltaTimeout > SCOREBOARD_TIMEOUT) {
        setCurrentRoutine(null);
        setShouldTimeout(true);
      }
    },
    POLLING_INTERVAL.SECOND,
    !shouldTimeout && !shouldDwell
  );

  useHotkeys(
    'd',
    (e) => {
      e.preventDefault();
      document.documentElement.setAttribute(
        'data-theme',
        darkMode ? null : 'dark'
      );
      setDarkMode(!darkMode);
      console.log('Dark Mode: ', !darkMode);
    },
    [darkMode]
  );

  useHotkeys(
    'esc, t',
    (e) => {
      e.preventDefault();
      setCurrentRoutine(null);
      setShouldDwell(false);
      setShouldTimeout(true);
    },
    [shouldTimeout]
  );

  useHotkeys(
    'm',
    (e) => {
      e.preventDefault();
      document.documentElement.setAttribute('data-theme', 'michigan');
    },
    []
  );

  useHotkeys(
    'left',
    (e) => {
      e.preventDefault();
      const newIndex = index === 0 ? 0 : index - 1;
      setIndex(newIndex);
      setCurrentRoutine(completedRoutines[newIndex]);
    },
    [index]
  );

  useHotkeys(
    'right',
    (e) => {
      e.preventDefault();
      const newIndex = index === sortedRoutines.length - 1 ? index : index + 1;
      setIndex(newIndex);
      setCurrentRoutine(completedRoutines[newIndex]);
    },
    [index, sortedRoutines]
  );

  useHotkeys(
    'c',
    (e) => {
      e.preventDefault();
      setShowCursor(!showCursor);
    },
    [showCursor]
  );

  const athleteImg = (athlete) => {
    const lastImg = image(athlete?.profileImg);
    const img = lastImg.filename;

    return (
      <img
        src={
          img ? `${MEDIAURL}${img}` : `${process.env.PUBLIC_URL}/headshotM.svg`
        }
        alt={athlete?.name === '' ? '' : athlete?.name + "'s photo"}
      />
    );
  };

  const athleteName = (athlete) => {
    if (!athlete?.name) {
      return 'Athlete';
    }

    if (athlete?.name.length > 16) {
      return fullNameToInitialLastName(athlete?.name, true);
    } else {
      return athlete?.name.toUpperCase();
    }
  };

  const currentAthleteName = isSmall
    ? fullNameToInitialLastName(currentAthlete?.name, true)
    : athleteName(currentAthlete);

  const nameNeedsScroll = isSmall && currentAthleteName.length >= 12;
  const scrollPct = (100 - (currentAthleteName.length - 2) * 10) * 0.7;
  // console.log({
  //   nameNeedsScroll,
  //   length: currentAthleteName.length,
  //   scrollPct,
  // });

  const teamInfo = (team) => {
    const name = team?.name;
    const logo =
      (team && JSON.parse(team?.logos)?.metaData.filename) || emptyTeam.logo;

    return (
      <>
        <Col
          xs={3}
          className="teamInfo"
          style={{ borderTop: `1vh solid ${teamColorB}` }}
        >
          {logo ? (
            <img src={`${MEDIAURL}${logo}`} alt={name ? `${name} logo` : ''} />
          ) : (
            virtiusV
          )}
        </Col>
        <Col xs={7} className="teamName">
          {}
        </Col>
      </>
    );
  };

  const apparatusLogo = () => {
    return (
      <Col className="apparatusLogo">
        {Icons?.[`${currentRoutine?.apparatus?.toUpperCase()}noText`] ??
          type?.toLowerCase()}
      </Col>
    );
  };

  const apparatusName = () => {
    return (
      <Row className="subScoresRow">
        <Col>
          <Col className="apparatus">
            {abbvToApparatusName[currentRoutine?.apparatus].toUpperCase()}
          </Col>
        </Col>
      </Row>
    );
  };

  const hasIndicator = currentScores?.scoreSB || currentScores?.scoreND;

  const indicators = () => {
    return (
      <Col className="indicators">
        {showStickBonus && (
          <Row className={currentScores.scoreSB ? 'green' : null}>
            {sCircle}
          </Row>
        )}
        {currentScores.scoreND ? (
          <Row className={currentScores.scoreND ? 'red' : null}>{nCircle}</Row>
        ) : null}
      </Col>
    );
  };

  const indicatorsSM = () => {
    return hasIndicator ? (
      <Col className="indicators" xs={1}>
        {showStickBonus && (
          <Row className={currentScores.scoreSB ? 'green' : null}>
            {sCircle}
          </Row>
        )}
        {currentScores.scoreND ? (
          <Row className={currentScores.scoreND ? 'red' : null}>{nCircle}</Row>
        ) : null}
      </Col>
    ) : null;
  };

  //  <Col xs={4} className="jScore">
  //   <Row style={{ display: 'block', float: 'right' }}>
  //     {convertMillipointsToDisplay(
  //       Math.max(...currentScores?.scoresJ),
  //       2
  //     )}
  //     <sub>Hi</sub>
  //   </Row>
  //   <Row style={{ display: 'block', float: 'right' }}>
  //     {convertMillipointsToDisplay(
  //       Math.min(...currentScores?.scoresJ),
  //       2
  //     )}
  //     <sub>Lo</sub>
  //   </Row>
  // </Col>

  const subScores = () => {
    return (
      <Row className="subScoresRow">
        {currentScores?.scoresSV?.length > 2 ? (
          <Col xs={4} className="jScore">
            {currentScores?.scoresSV.map((sv, index) => {
              const numScores = currentScores?.scoresSV.length;
              return (
                <Row
                  style={{
                    display: 'block',
                    float: 'right',
                    fontSize: `${14 / numScores}vw`,
                    width: '100%',
                  }}
                >
                  {convertMillipointsToDisplay(sv, 2)}
                  <sub style={{ fontSize: `${9 / numScores}vw` }}>
                    SV{index + 1}
                  </sub>
                </Row>
              );
            })}
          </Col>
        ) : currentScores?.scoresSV?.length > 0 ? (
          <Col xs={4} className="jScore">
            {currentScores?.scoresSV.map((sv, index) => {
              return (
                <Row style={{ display: 'block', float: 'right' }}>
                  {convertMillipointsToDisplay(sv, 2)}
                  <sub>SV{index + 1}</sub>
                </Row>
              );
            })}
          </Col>
        ) : null}
        {currentScores?.scoresJ?.length > 2 ? (
          <Col xs={4} className="jScore">
            {currentScores?.scoresJ.map((j, index) => {
              const numScores = currentScores?.scoresJ.length;
              return (
                <Row
                  style={{
                    display: 'block',
                    float: 'right',
                    fontSize: `${14 / numScores}vw`,
                    width: '100%',
                  }}
                >
                  {convertMillipointsToDisplay(j, 2)}
                  <sub style={{ fontSize: `${9 / numScores}vw` }}>
                    J{index + 1}
                  </sub>
                </Row>
              );
            })}
          </Col>
        ) : currentScores?.scoresJ?.length > 0 ? (
          <Col xs={4} className="jScore">
            {currentScores?.scoresJ.map((j, index) => {
              return (
                <Row style={{ display: 'block', float: 'right' }}>
                  {convertMillipointsToDisplay(j, 2)}
                  <sub>J{index + 1}</sub>
                </Row>
              );
            })}
          </Col>
        ) : null}

        {currentScores?.scoreDType === 'SV' ? (
          <Col xs={4} className="dScore">
            {convertMillipointsToDisplay(currentScores.scoreD, 2)}
            <sub>SV</sub>
          </Col>
        ) : null}
        {['D', 'D1'].includes(currentScores?.scoreDType) ? (
          <Col xs={2} className="dScore">
            {convertMillipointsToDisplay(currentScores.scoreD, 1)}
            <sub>D</sub>
          </Col>
        ) : null}
        {currentScores?.scoreEAverage ? (
          <Col xs={6} className="eScore">
            {convertMillipointsToDisplay(currentScores.scoreEAverage, 3)}
            <sub>E</sub>
          </Col>
        ) : null}
        {currentScores?.scoreND ? (
          <Col xs={4} className="ndScore">
            -{convertMillipointsToDisplay(currentScores.scoreND, 1)}
            <sub>ND</sub>
          </Col>
        ) : null}
      </Row>
    );
  };

  const subScoresSM = () => {
    return (
      <Row className="subScoresRow">
        {currentScores?.scoresJ?.length > 2 ? (
          <Col xs={12} className="jScore">
            {currentScores?.scoresJ.map((j, index) => {
              const numScores = currentScores?.scoresJ.length;
              return (
                <Col
                  style={{
                    display: 'block',
                    fontSize: `${24 / numScores}vw`,
                    paddingLeft: '0',
                    paddingRight: '0',
                  }}
                >
                  {convertMillipointsToDisplay(j, 2)}
                  <sub style={{ fontSize: `${12 / numScores}vw` }}>
                    J{index + 1}
                  </sub>
                </Col>
              );
            })}
          </Col>
        ) : // <>
        //   <Col xs={12} className="jScore">
        //     {convertMillipointsToDisplay(
        //       Math.max(...currentScores?.scoresJ),
        //       2
        //     )}
        //     <sub>Hi</sub>
        //   </Col>
        //   <Col style={{ display: 'block' }}>
        //     {convertMillipointsToDisplay(
        //       Math.min(...currentScores?.scoresJ),
        //       2
        //     )}
        //     <sub>Lo</sub>
        //   </Col>
        // </>
        currentScores?.scoresJ?.length > 0 ? (
          <Col xs={12} className="jScore">
            {currentScores?.scoresJ.map((j, index) => {
              return (
                <Col style={{ display: 'block' }}>
                  {convertMillipointsToDisplay(j, 2)}
                  <sub>J{index + 1}</sub>
                </Col>
              );
            })}
          </Col>
        ) : null}
      </Row>
    );
  };

  const mainScore = () => {
    if (isComplete) {
      return convertMillipointsToDisplay(currentRoutine?.score, scorePrecision);
    }

    if (isOnAir) {
      if (isMeanGirls) {
        return 'Go';
      }

      //return 'Go\xa0\xa0\xa0\xa0\xa0\xa0';
      return 'On Air';
    }

    if (isOnEval) {
      if (isMeanGirls) {
        return 'Scoring';
      }

      return 'On Eval';
    }

    return 'Standby\xa0';
  };

  const placeholder = (
    <MatchCountdown
      sessionId={sessionId}
      apparatus={isApparatus ? type : null}
    />
  );

  return (
    <Container fluid className={['scoreboardContainer', size].join(' ')}>
      <Row
        className={['scoreboardDisplay', showCursor ? 'showCursor' : null].join(
          ' '
        )}
      >
        {currentRoutine ? (
          <>
            <Row className="topHalf">
              <Row className="scoreboardHeader">{teamInfo(currentTeam)}</Row>
              <Row className="scoreboardBody">
                <Col
                  xs={isXSmall ? null : 3}
                  className={isSmall ? 'athleteImgSM' : 'athleteImg'}
                >
                  {athleteImg(currentAthlete)}
                </Col>
                <Col
                  xs={isXSmall ? 12 : 9}
                  className={['mainScore', !isComplete ? 'msg' : null].join(
                    ' '
                  )}
                  style={{ borderTop: `1vh solid ${teamColorA}` }}
                >
                  <Row className="score">
                    <Col
                      className={isComplete ? is4digsClass : null}
                      xs={isSmall ? (hasIndicator ? 11 : 12) : null}
                    >
                      {mainScore()}
                    </Col>
                    {isComplete
                      ? isSmall
                        ? indicatorsSM()
                        : indicators()
                      : null}
                  </Row>
                  <Row className="name">
                    {nameNeedsScroll ? (
                      <Keyframes
                        name="marquee"
                        _0={{ transform: 'translateX(0%)' }}
                        _10={{ transform: 'translateX(0%)' }}
                        _45={{ transform: `translateX(${scrollPct}%)` }}
                        _55={{ transform: `translateX(${scrollPct}%)` }}
                        _90={{ transform: 'translateX(0%)' }}
                        _100={{ transform: 'translateX(0%)' }}
                      />
                    ) : null}
                    <Col className={nameNeedsScroll ? 'animateName' : null}>
                      {isSmall
                        ? fullNameToInitialLastName(currentAthlete?.name, true)
                        : athleteName(currentAthlete)}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Row>
            <Row className="bottomHalf">
              <Row className="scoreboardFooter">
                <Col
                  xs={isSmall ? 2 : 3}
                  className={[
                    'logoCol',
                    isOnAir ? 'onAir' : null,
                    isOnEval ? 'onEval' : null,
                  ].join(' ')}
                >
                  {apparatusLogo()}
                </Col>
                <Col
                  xs={isSmall ? 10 : 9}
                  className={[
                    'subScores',
                    isOnAir ? 'onAir' : null,
                    isOnEval ? 'onEval' : null,
                  ].join(' ')}
                >
                  {isOnAir || isOnEval || !isComplete || isOpenScoring
                    ? apparatusName()
                    : isSmall
                    ? subScoresSM()
                    : subScores()}
                </Col>
              </Row>
            </Row>
          </>
        ) : (
          placeholder
        )}
      </Row>
    </Container>
  );
}

export function ScoreboardTeamsDisplay({
  sessionId,
  className,
  isDarkMode = false,
}) {
  const displayConfig = useDisplayConfig();
  const { scorePrecision } = displayConfig;
  const [darkMode, setDarkMode] = useState(isDarkMode);
  const [showCursor, setShowCursor] = useState(false);
  const [teamsByRank, setTeamsByRank] = useState([]);
  const [requestedCopy, setRequestedCopy] = useState(false);
  const { data, networkStatus } = useQuery(GetFullSession, {
    variables: { id: sessionId },
  });

  const teamsData = useTeamsByApparatusData();
  const scoreData = teamsData(data.getSession);
  const shouldRankTeams = teamsByRank.length === scoreData?.teams?.length;
  const teams = shouldRankTeams
    ? teamsByRank.map((t) => scoreData.teams.find((s) => s.order === t.order))
    : scoreData.teams.sort((a, b) => a.order - b.order);
  const queryParams = useQueryParams();
  const teamsParams = queryParams.get('teams');
  const hasTeamsParams = queryParams.has('teams');
  const teamsFilter = hasTeamsParams ? [] : teams;

  hasTeamsParams &&
    teamsParams.split('').forEach((type, i) => {
      const isTeam = isLetter(type) && alphaVal(type) < teams.length;
      const hasTeam = isTeam && teams[alphaVal(type)];
      teamsFilter[i] = hasTeam;
    });

  useEffect(() => {
    if (darkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
    }
  }, [darkMode]);

  useEffect(() => {
    if (data && requestedCopy && networkStatus !== NetworkStatus.refetch) {
      setRequestedCopy(false);
    }
  }, [requestedCopy, networkStatus, data]);

  useInterval(
    async () => {
      setShowCursor(false);
    },
    POLLING_INTERVAL.MINUTE,
    showCursor
  );

  useHotkeys(
    'd',
    (e) => {
      e.preventDefault();
      document.documentElement.setAttribute(
        'data-theme',
        darkMode ? null : 'dark'
      );
      setDarkMode(!darkMode);
      console.log('Dark Mode: ', !darkMode);
    },
    [darkMode]
  );

  useHotkeys(
    'c',
    (e) => {
      e.preventDefault();
      setShowCursor(!showCursor);
    },
    [showCursor]
  );

  useHotkeys(
    'r',
    (e) => {
      e.preventDefault();
      const rankedTeams = teams.sort((a, b) => {
        if (a.rank === '') {
          return 1;
        } else if (b.rank === '') {
          return -1;
        } else {
          return a.rank - b.rank;
        }
      });
      setTeamsByRank(rankedTeams);
    },
    [teams]
  );

  useHotkeys('esc', (e) => {
    e.preventDefault();
    setTeamsByRank([]);
  });

  return (
    <Container fluid className={['scoreboardContainer', className].join(' ')}>
      <Row
        className={['scoreboardDisplay', showCursor ? 'showCursor' : null].join(
          ' '
        )}
      >
        {teamsFilter.map((t, index) => {
          const { finalScore, rank, isTie } = t;
          const { name, logo, colors } = teamBrand(t);
          const rows = teamsFilter.length;

          return (
            <Row
              className="scoreboardTeamSingleLine"
              style={{ height: `${(1 / rows) * 100}%` }}
              key={index}
            >
              <Col
                className="teamLogo"
                xs={1}
                style={{
                  background: `linear-gradient(-45deg, ${colors[0]} , ${colors[1]})`,
                }}
              >
                <Row className="logoRow" style={{ height: '50%' }}>
                  {logo ? (
                    <img
                      src={`${MEDIAURL}${logo}`}
                      alt={name ? `${name} logo` : ''}
                    />
                  ) : (
                    virtiusV
                  )}
                </Row>
                <Row className="rank" style={{ height: '50%' }}>
                  {!finalScore ? null : (
                    <div className="rankText">
                      <sub>#</sub>
                      <span>{rank}</span>
                      <span style={{ fontSize: '50%', paddingLeft: '1rem' }}>
                        {isTie ? 'T' : ''}
                      </span>
                    </div>
                  )}
                </Row>
              </Col>
              <Col
                className="teamName"
                xs={6}
                style={{ backgroundColor: `${colors[0]}` }}
              >
                <span>{name}</span>
              </Col>
              <Col
                className="teamScore"
                xs={5}
                style={{ backgroundColor: `${colors[0]}` }}
              >
                <span>
                  {finalScore || convertMillipointsToDisplay(0, scorePrecision)}
                </span>
              </Col>
            </Row>
          );
        })}
      </Row>
    </Container>
  );
}

function ScoreboardTeamsByApparatusDisplay({ sessionId }) {
  const displayConfig = useDisplayConfig();
  const { scorePrecision } = displayConfig;
  const [darkMode, setDarkMode] = useState(true);
  const [showCursor, setShowCursor] = useState(false);
  const [teamsByRank, setTeamsByRank] = useState([]);
  const [requestedCopy, setRequestedCopy] = useState(false);
  const { data, networkStatus } = useQuery(GetFullSession, {
    variables: { id: sessionId },
  });

  const gender = data.getSession.gender;
  const apparatusArray = Object.keys(GYMapparatus(gender));
  const teamsData = useTeamsByApparatusData();
  const scoreData = teamsData(data.getSession);
  const shouldRankTeams = teamsByRank.length === scoreData?.teams?.length;
  const teams = shouldRankTeams
    ? teamsByRank.map((t) => scoreData.teams.find((s) => s.order === t.order))
    : scoreData.teams.sort((a, b) => a.order - b.order);
  const queryParams = useQueryParams();
  const teamsParams = queryParams.get('teams');
  const hasTeamsParams = queryParams.has('teams');
  const teamsFilter = hasTeamsParams ? [] : teams;

  const is4digsClass = scorePrecision === 4 ? 'is4digs' : '';

  hasTeamsParams &&
    teamsParams.split('').forEach((type, i) => {
      const isTeam = isLetter(type) && alphaVal(type) < teams.length;
      const hasTeam = isTeam && teams[alphaVal(type)];
      teamsFilter[i] = hasTeam;
    });

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', 'dark');
  });

  useEffect(() => {
    if (data && requestedCopy && networkStatus !== NetworkStatus.refetch) {
      setRequestedCopy(false);
    }
  }, [requestedCopy, networkStatus, data]);

  useInterval(
    async () => {
      setShowCursor(false);
    },
    POLLING_INTERVAL.MINUTE,
    showCursor
  );

  useHotkeys(
    'd',
    (e) => {
      e.preventDefault();
      document.documentElement.setAttribute(
        'data-theme',
        darkMode ? null : 'dark'
      );
      setDarkMode(!darkMode);
      console.log('Dark Mode: ', !darkMode);
    },
    [darkMode]
  );

  useHotkeys(
    'c',
    (e) => {
      e.preventDefault();
      setShowCursor(!showCursor);
    },
    [showCursor]
  );

  useHotkeys(
    'r',
    (e) => {
      e.preventDefault();
      const rankedTeams = teams.sort((a, b) => {
        if (a.rank === '') {
          return 1;
        } else if (b.rank === '') {
          return -1;
        } else {
          return a.rank - b.rank;
        }
      });
      setTeamsByRank(rankedTeams);
    },
    [teams]
  );

  useHotkeys('esc', (e) => {
    e.preventDefault();
    setTeamsByRank([]);
  });

  return (
    <Container fluid className="scoreboardContainer">
      <Row
        className={['scoreboardDisplay', showCursor ? 'showCursor' : null].join(
          ' '
        )}
      >
        <Row
          className="scoreboardTeamSingleLine"
          style={{ height: `${10}%`, background: '#111' }}
        >
          <Col xs={1}></Col>
          {apparatusArray.map((a) => {
            const isSmallFontClass =
              apparatusArray.length > 4 ? 'isSmallFont' : '';
            return (
              <Col
                key={a}
                className={[
                  'teamApparatusScore',
                  is4digsClass,
                  isSmallFontClass,
                ].join(' ')}
              >
                <span className="teamApparatusHeader">{a}</span>
                {Icons?.[`${a}noText`]}
              </Col>
            );
          })}
          <Col className="teamApparatusScore" xs={3}>
            <span>Total</span>
          </Col>
        </Row>
        {teamsFilter.map((team, index) => {
          const { finalScore, rank, isTie } = team;
          const { name, logo, colors } = teamBrand(team);
          const rows = teamsFilter.length;
          const isSmallFontClass = team.events.length > 4 ? 'isSmallFont' : '';

          return (
            <Row
              className="scoreboardTeamSingleLine"
              style={{ height: `${(1 / rows) * 90}%` }}
              key={index}
            >
              <Col
                className="teamLogo"
                xs={1}
                style={{
                  background: `linear-gradient(-45deg, ${colors[0]} , ${colors[1]})`,
                }}
              >
                <Row className="logoRow" style={{ height: '50%' }}>
                  {logo ? (
                    <img
                      src={`${MEDIAURL}${logo}`}
                      alt={name ? `${name} logo` : ''}
                    />
                  ) : (
                    virtiusV
                  )}
                </Row>
                <Row className="rank" style={{ height: '50%' }}>
                  {!finalScore ? null : (
                    <div className="rankText">
                      <sub>#</sub>
                      <span>{rank}</span>
                      <span style={{ fontSize: '50%', paddingLeft: '1rem' }}>
                        {isTie ? 'T' : ''}
                      </span>
                    </div>
                  )}
                </Row>
              </Col>
              {apparatusArray.map((event) => {
                const teamEvent = team.events.find(
                  (e) => e.apparatus === event
                );

                return (
                  <Col
                    className={[
                      'teamApparatusScore',
                      is4digsClass,
                      isSmallFontClass,
                    ].join(' ')}
                    style={{ backgroundColor: `${colors[0]}` }}
                    key={event}
                  >
                    <span>{(teamEvent && teamEvent.eventScore) || ''}</span>
                  </Col>
                );
              })}
              <Col
                className="teamApparatusTotalScore"
                xs={3}
                style={{ backgroundColor: `${colors[0]}` }}
              >
                <span>
                  {finalScore || convertMillipointsToDisplay(0, scorePrecision)}
                </span>
              </Col>
            </Row>
          );
        })}
      </Row>
    </Container>
  );
}

export default function ScoreboardDisplay(props) {
  const { sessionId, type } = props;
  let selectedScoreboard = null;

  switch (type.toUpperCase()) {
    case 'TEAMS':
      selectedScoreboard = <ScoreboardTeamsDisplay {...props} />;
      break;
    case 'TEAMSBYAPPARATUS':
      selectedScoreboard = <ScoreboardTeamsByApparatusDisplay {...props} />;
      break;
    default:
      selectedScoreboard = <ScoreboardSoloDisplay {...props} />;
      break;
  }

  return (
    <>
      {selectedScoreboard}
      <SessionSubscriptionManager sessionId={sessionId} />
    </>
  );
}
