import { API, graphqlOperation } from 'aws-amplify';
import {
  createSession,
  getSession,
  updateSession,
  checkJoinSession,
  syncSessionById,
  syncStreamById,
  syncNewStreamBySession,
  updateSessionTeam,
  createSessionTeam,
  createLineup,
  updateLineup,
  syncLineupById,
  createStreamNoCam,
} from '../../graphql/graphql';
import { createStreamWowza, deleteSessionTeam } from '../../graphql/mutations';
import { nanoid } from 'nanoid';
import { sessionStatus, accessType } from '../_constants';
import { sessionActions } from '../_actions';
import { SessionHostType } from '../../models';

export const sessionService = {
  join,
  create,
  load,
  update,
  streamRequest,
  streamRequestNoCam,
  subscribe,
  unsubscribe,
  subscribeStream,
  subscribeNewStream,
  subscribeAllStreams,
  unsubscribeAllStreams,
  addLineup,
  editLineup,
  subscribeLineup,
  subscribeAllLineups,
  unsubscribeAllLineups,
  updateProducer,
  updateStatus,
};

async function streamRequest(payload) {
  console.log(payload);
  return await API.graphql(
    graphqlOperation(createStreamWowza, { payload: JSON.stringify(payload) })
  );
}

async function load(key) {
  return await API.graphql(graphqlOperation(getSession, { sessionKey: key }));
}

async function join(key) {
  return await API.graphql(
    graphqlOperation(checkJoinSession, { sessionKey: key })
  );
}

async function streamRequestNoCam(input) {
  return await API.graphql(
    graphqlOperation(createStreamNoCam, { input: input })
  );
}

// Createing a session handles session, sessionTeam assignments, sessionLineup creations
async function create(input, teamChange) {
  const payload = {
    sessionKey: nanoid(10),
    name: input.name,
    type: input.type,
    access: accessType.PRIVATE,
    startAt: input.startAt,
    status: sessionStatus.CREATED,
    gender: input.gender,
    competition: input.competition,
    judging: input.judging,
    judgingRequired: input.judgingRequired,
    apparatus: input.apparatus,
    teamScoring: input.teamScoring,
    alternating: input.alternating,
    judgePanel: input.judgePanel,
    hostId: input.hostId,
    hostType: input.hostType || SessionHostType.NONE,
    //endAt: String
  };

  const createSessionResponse = await API.graphql(
    graphqlOperation(createSession, { input: payload })
  );
  const session = createSessionResponse.data.createSession;

  const createSessionTeamsResponse = await Promise.all(
    teamChange.creates.map((input) => {
      return API.graphql(
        graphqlOperation(createSessionTeam, {
          input: {
            ...input,
            sessionId: session.id,
          },
        })
      );
    })
  );

  await Promise.all(
    teamChange.creates.map((input, i) => {
      return API.graphql(
        graphqlOperation(createLineup, {
          input: {
            sessionId: session.id,
            title: session.name,
            teamId: input.teamId,
            sessionTeamId:
              createSessionTeamsResponse[i].data.createSessionTeam.id,
            order: i,
          },
        })
      );
    })
  );

  return session;
}

async function update(input, teamChange = null) {
  const payload = {
    id: input.id,
    name: input.name,
    type: input.type,
    access: input.access,
    startAt: input.startAt,
    endAt: input.endAt ? input.endAt : null,
    gender: input.gender,
    competition: input.competition,
    judging: input.judging,
    judgingRequired: input.judgingRequired,
    apparatus: input.apparatus,
    _version: input._version,
    status: input.status,
    teamScoring: input.teamScoring,
    alternating: input.alternating,
    judgePanel: input.judgePanel,
    hostId: input.hostId,
    hostType: input.hostType ? input.hostType : null,
    rtnId: input.rtnId,
  };

  const sessionUpdate = API.graphql(
    graphqlOperation(updateSession, { input: payload })
  );

  const deleteBatch = teamChange
    ? Promise.all(
        teamChange.updates
          .filter((i) => i?._deleted)
          .map((input) => {
            return API.graphql(
              graphqlOperation(deleteSessionTeam, {
                input: { id: input.id, _version: input._version },
              })
            );
          })
      )
    : null;

  const updateBatch = teamChange
    ? Promise.all(
        teamChange.updates
          .filter((i) => !i?._deleted)
          .map((input) => {
            return API.graphql(graphqlOperation(updateSessionTeam, { input }));
          })
      )
    : null;
  const createBatch = teamChange
    ? Promise.all(
        teamChange.creates.map((input) => {
          return API.graphql(graphqlOperation(createSessionTeam, { input }));
        })
      )
    : null;

  // Todo: need to sequence this to update the sessionlast to catch any changes to team relationships
  return await Promise.all([
    sessionUpdate,
    createBatch,
    updateBatch,
    deleteBatch,
  ]);
}

async function subscribe(id, dispatch) {
  return await API.graphql(
    graphqlOperation(syncSessionById, { id: id })
  ).subscribe({
    next: (streamData) => {
      //console.log(streamData);
      // TODO: need to figure out where data goes when you sync session
      dispatch(
        sessionActions.syncSession(streamData.value.data.subscribeToSession)
      );
    },
    error: (error) => {
      console.warn(error);
      console.log(`Session ${id} subscription error.`);
    },
  });
}

async function subscribeStream(id, channel, dispatch) {
  return await API.graphql(
    graphqlOperation(syncStreamById, { id: id })
  ).subscribe({
    next: (streamData) => {
      dispatch(
        sessionActions.syncStreamStatus(
          streamData.value.data.subscribeToStream,
          channel
        )
      );
    },
    error: (error) => {
      console.warn(error);
      console.log(`Stream ${id} subscription error.`);
    },
  });
}

async function subscribeNewStream(id, dispatch) {
  return await API.graphql(
    graphqlOperation(syncNewStreamBySession, { sessionId: id })
  ).subscribe({
    next: (streamData) => {
      console.log(streamData);
      dispatch(
        sessionActions.syncNewStream(streamData.value.data.subscribeToNewStream)
      );
    },
    error: (error) => {
      console.warn(error);
      console.log(`Stream ${id} subscription error.`);
    },
  });
}

async function subscribeAllStreams(streams, dispatch) {
  return await Promise.all(
    streams.map((stream) => {
      return API.graphql(
        graphqlOperation(syncStreamById, { id: stream.id })
      ).subscribe({
        next: (streamData) => {
          dispatch(
            sessionActions.syncStreamStatus(
              streamData.value.data.subscribeToStream
            )
          );
        },
        error: (error) => {
          console.warn(error);
          console.log(`Stream ${stream.id} subscription error.`);
        },
      });
    })
  );
}

function unsubscribeAllStreams(subs) {
  subs &&
    subs.forEach((sub) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  return Promise.resolve(true);
}

function unsubscribe(sub) {
  if (sub) {
    sub.unsubscribe();
  }
  return Promise.resolve(true);
}

async function addLineup(input) {
  const createLineups = Promise.all(
    input.map((lineup) => {
      return API.graphql(graphqlOperation(createLineup, { input: lineup }));
    })
  );

  return await createLineups;
}

async function editLineup(input) {
  return await API.graphql(graphqlOperation(updateLineup, { input: input }));
}

async function subscribeLineup(id, dispatch) {
  return await API.graphql(
    graphqlOperation(syncLineupById, { id: id })
  ).subscribe({
    next: (lineupData) => {
      dispatch(
        sessionActions.syncLineup(lineupData.value.data.subscribeToLineup)
      );
    },
    error: (error) => {
      console.warn(error);
      console.log(`Lineup ${id} subscription error.`);
    },
  });
}

async function subscribeAllLineups(lineups, dispatch) {
  return await Promise.all(
    lineups.map((lineup) => {
      return API.graphql(
        graphqlOperation(syncLineupById, { id: lineup.id })
      ).subscribe({
        next: (lineupData) => {
          dispatch(
            sessionActions.syncLineup(lineupData.value.data.subscribeToLineup)
          );
        },
        error: (error) => {
          console.warn(error);
          console.log(`Lineup ${lineup.id} subscription error.`);
        },
      });
    })
  );
}

function unsubscribeAllLineups(subs) {
  subs &&
    subs.forEach((sub) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  return Promise.resolve(true);
}

async function updateProducer(input) {
  const payload = {
    id: input.id,
    _version: input._version,
    producer: JSON.stringify(input.producer),
  };

  return await API.graphql(graphqlOperation(updateSession, { input: payload }));
}

async function updateStatus(input) {
  const payload = {
    id: input.id,
    _version: input._version,
    status: input.status,
  };

  return await API.graphql(graphqlOperation(updateSession, { input: payload }));
}
