import {
  CAMERA_DEFAULT_SETTINGS,
  mApparatusAbbv,
  wApparatusAbbv,
} from './constants';
import { GenderType } from '../models';

export const teamScoring = (type) => {
  switch (type) {
    case 'TOP5OF6':
      return 'Best 5 scores out of 6';
    case 'TOP6HL':
      return 'First 6 scores drop hi/lo';
    case 'TOP6':
      return 'First 6 scores';
    case 'TOP5':
      return 'First 5 scores';
    case 'TOP4':
      return 'First 4 scores';
    case 'TOP3':
      return 'First 3 scores';
    case 'BEST5':
      return 'Best 5 scores overall';
    case 'BEST4':
      return 'Best 4 scores overall';
    case 'BEST3':
      return 'Best 3 scores overall';
    case 'ALL':
      return 'All scores count';
    case 'NONE':
      return 'No team scoring';
    default:
      return '';
  }
};

export const isExhibition = (type, index) => {
  switch (type) {
    case 'TOP5OF6':
    case 'TOP6HL':
    case 'TOP6':
      return index > 6;
    case 'TOP5':
    case 'BEST5':
      return index > 5;
    case 'TOP4':
    case 'BEST4':
      return index > 4;
    case 'TOP3':
      return index > 3;
    case 'BEST3':
      return 'Best 3 scores overall';
    case 'ALL':
      return 'All scores count';
    case 'NONE':
      return 'No team scoring';
    default:
      return '';
  }
};

// Generic function to handle gender / session format
// Option 1: Gender M/F
// Option 2: Format Solo / Dual / Multi
// Option 3: Order (of the lineup, 0, 1, 2), i.e. Home / Away etc. starting order
export const numToApparatus = (num, short = false, gender, format, order) => {
  /*  For future use when we have multi and different apparatus start
  switch(format) {
    case SessionType.SOLO: 
    case SessionType.DUAL:
    case SessionType.MULTI: 
    default: 
      break;
  }
  */

  switch (gender) {
    case GenderType.MALE:
      return numToApparatusM(num, short);
    case GenderType.FEMALE:
      return numToApparatusW(num, short);
    default:
      return '';
  }
};

// 1-6, short is abbreviated
export const numToApparatusM = (num, short = false) => {
  switch (num) {
    case 1:
      return short ? 'FX' : 'Floor Exercise';
    case 2:
      return short ? 'PH' : 'Pommel Horse';
    case 3:
      return short ? 'SR' : 'Still Rings';
    case 4:
      return short ? 'VT' : 'Vault';
    case 5:
      return short ? 'PB' : 'Parallel Bars';
    case 6:
      return short ? 'HB' : 'Horizontal Bar';
    default:
      return '';
  }
};

// 1-4, short is abbreviated
export const numToApparatusW = (num, short = false) => {
  switch (num) {
    case 1:
      return short ? 'VT' : 'Vault';
    case 2:
      return short ? 'UB' : 'Uneven Bars';
    case 3:
      return short ? 'BB' : 'Balance Beam';
    case 4:
      return short ? 'FX' : 'Floor Exercise';
    default:
      return '';
  }
};

export const abbvToApparatusName = {
  FX: 'Floor Exercise',
  PH: 'Pommel Horse',
  SR: 'Still Rings',
  VT: 'Vault',
  PB: 'Parallel Bars',
  HB: 'Horizontal Bar',
  BB: 'Balance Beam',
  UB: 'Uneven Bars',
};

export const WAGapparatus = {
  VT: 'Vault',
  UB: 'Uneven Bars',
  BB: 'Balance Beam',
  FX: 'Floor Exercise',
};

export const MAGapparatus = {
  FX: 'Floor Exercise',
  PH: 'Pommel Horse',
  SR: 'Still Rings',
  VT: 'Vault',
  PB: 'Parallel Bars',
  HB: 'Horizontal Bar',
};

export const GYMapparatus = (gender) => {
  switch (gender) {
    case GenderType.MALE:
      return MAGapparatus;
    case GenderType.FEMALE:
      return WAGapparatus;
    default:
      return {};
  }
};

export const GYMapparatusAbbv = (gender) => {
  switch (gender) {
    case GenderType.MALE:
      return mApparatusAbbv;
    case GenderType.FEMALE:
      return wApparatusAbbv;
    default:
      return [];
  }
};

export const isMWApparatus = (text) => {
  const options = [...mApparatusAbbv, ...wApparatusAbbv];
  return options.includes(text.toUpperCase());
};

export const isLetter = (str) => {
  return str.length === 1 && /^[a-z]{1}$/i.test(str);
};

export const alphaVal = (s) => s.toLowerCase().charCodeAt(0) - 97;

export const strApparatusToArray = (str) => {
  if (str === null) {
    return [];
  }

  const result = [];
  str.split('').forEach((char, i) => {
    if (parseInt(char) > 0) {
      result.push(i);
    }
  });

  return result;
};

export const arrayToStrApparatus = (array) => {
  if (array === null) {
    return '';
  }
  let result = '000000';

  if (array.length > 0) {
    array.forEach((i) => {
      if (i < result.length) {
        result = result.substring(0, i) + '1' + result.substring(i + 1);
      }
    });
  }

  return result;
};

export const larixURL = (settings = null) => {
  if (!settings?.url) {
    return '';
  }

  const {
    url,
    port,
    name,
    mode,
    latency,
    maxbw,
    pass,
    streamId,
    camera,
    res,
    fps,
    bitrate,
    format,
    keyframe,
    audiobit,
    audiochannels,
    audiosamples,
  } = { ...CAMERA_DEFAULT_SETTINGS, ...settings };

  const parts = [
    `larix://set/v1?conn[][url]=${url}:${port}`,
    `&conn[][name]=${encodeURI(name)}`,
    `&conn[][mode]=${mode}`,
    `&conn[][srtlatency]=${latency}`,
    `&conn[][srtmaxbw]=${maxbw}`,
    pass ? `&conn[][srtpass]=${pass}` : '',
    streamId ? `&conn[][srtstreamid]=${streamId}` : '',
    `&enc[vid][camera]=${camera}`,
    `&enc[vid][res]=${res}`,
    `&enc[vid][fps]=${fps}`,
    `&enc[vid][bitrate]=${bitrate}`,
    `&enc[vid][format]=${format}`,
    `&enc[vid][keyframe]=${keyframe}`,
    `&enc[aud][bitrate]=${audiobit}`,
    `&enc[aud][channels]=${audiochannels}`,
    `&enc[aud][samples]=${audiosamples}`,
  ];

  return parts.join('');
};

export const dateTime = (dateObject, showTime = true) => {
  const dateOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };

  if (!dateObject) {
    return '';
  }
  dateObject = new Date(dateObject);

  const date = dateObject.toLocaleString('en-US', dateOptions);
  const time = dateObject.toLocaleString('en-US', timeOptions);

  return showTime ? `${date} @ ${time}` : date;
};

export const YYYYMMDD = (dateObject) => {
  if (!dateObject) {
    return '';
  }
  const date = new Date(dateObject);
  const local = new Date();
  const offset = local.getTimezoneOffset();

  const dateOffset = new Date(date.getTime() - offset * 60000);
  const YYYY = dateOffset.getUTCFullYear();
  const MM = String(dateOffset.getUTCMonth() + 1).padStart(2, '0');
  const DD = String(dateOffset.getUTCDate()).padStart(2, '0');

  return `${YYYY}${MM}${DD}`;
};

export const timeWithSeconds = (dateObject) => {
  if (!dateObject) {
    return '';
  }
  dateObject = new Date(dateObject);
  const time = dateObject.toLocaleTimeString('en-US');

  return time;
};

export function teamBrand(team) {
  const logo =
    (team && team?.logos && JSON.parse(team?.logos)?.metaData.filename) || '';
  const name = (team && displayName(team.name, team?.altNames)) || '';
  const names = team?.altNames || '';
  const colors = (team?.colors && JSON.parse(team?.colors)) || '';

  return { name, colors, logo, names };
}

// Searches for #hashtag and then returns that for the name
// Adding index in case we have the same team an want to choose another name
export const displayName = (name, altNames, index = -1) => {
  if (!altNames) {
    return name;
  }

  // Check to see if need to parse altNames
  const parsedAltNames = JSON.parse(altNames);
  const filteredAltNames = parsedAltNames.filter((n) => n[0] === '#');

  if (
    !parsedAltNames ||
    parsedAltNames.length === 0 ||
    !filteredAltNames ||
    filteredAltNames.length === 0
  ) {
    return name;
  }

  if (index > -1 && index < filteredAltNames.length) {
    return filteredAltNames[index].substring(1);
  } else {
    for (let i = 0; i < filteredAltNames.length; i++) {
      if (filteredAltNames[i][0] === '#') {
        return filteredAltNames[i].substring(1);
      }
    }
  }

  return name;
};

export const fullNameToInitialLastName = (name, uppercase = false) => {
  let result = '';

  if (name) {
    const first = name.split(' ', 1);
    result = `${name.replace(first, first[0][0] + '.')}`;
    if (uppercase) {
      result = result.toUpperCase();
    }
  }

  return result;
};

export const sec2time = (timeInSeconds, ms = false) => {
  if (timeInSeconds === null || timeInSeconds === undefined) {
    return null;
  }

  let pad = function (num, size) {
      return ('000' + num).slice(size * -1);
    },
    time = parseFloat(timeInSeconds).toFixed(3),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60),
    milliseconds = time.slice(-3);

  return (
    (hours ? `${hours}:` : '') +
    pad(minutes, 2) +
    ':' +
    pad(seconds, 2) +
    (ms ? `.${pad(milliseconds, 3)}` : '')
  );
};

// Color conversions
export const hexToRgb = (hex) => {
  if (typeof hex !== 'string') {
    return null;
  }
  let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const rgbToHex = (r, g, b) => {
  if (typeof r !== 'number' || typeof g !== 'number' || typeof b !== 'number') {
    return null;
  }
  return (
    '#' +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).toUpperCase().slice(1)
  );
};

export const rgbToLab = (r, g, b) => {
  if (typeof r !== 'number' || typeof g !== 'number' || typeof b !== 'number') {
    return null;
  }
  r = r / 255;
  g = g / 255;
  b = b / 255;
  let x, y, z;
  r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
  g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
  b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
  x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
  y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.0;
  z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
  x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116;
  y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116;
  z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116;
  return { l: 116 * y - 16, a: 500 * (x - y), b: 200 * (y - z) };
};

export const hexToLab = (hex) => {
  if (typeof hex !== 'string') {
    return null;
  }
  const rgb = hexToRgb(hex);
  return rgb ? rgbToLab(rgb.r, rgb.g, rgb.b) : null;
};

// CIE DE2000 score for deltaE, note calculation is quasimetric
export const deltaE = (hexA, hexB) => {
  if (typeof hexA !== 'string' || typeof hexB !== 'string') {
    return null;
  }
  let labA = hexToLab(hexA);
  let labB = hexToLab(hexB);
  let deltaL = labA.l - labB.l;
  let deltaA = labA.a - labB.a;
  let deltaB = labA.b - labB.b;
  let c1 = Math.sqrt(labA.a * labA.a + labA.b * labA.b);
  let c2 = Math.sqrt(labB.a * labB.a + labB.b * labB.b);
  let deltaC = c1 - c2;
  let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
  deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
  let sc = 1.0 + 0.045 * c1;
  let sh = 1.0 + 0.015 * c1;
  let deltaLKlsl = deltaL / 1.0;
  let deltaCkcsc = deltaC / sc;
  let deltaHkhsh = deltaH / sh;
  let i =
    deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
  return i < 0 ? 0 : Math.sqrt(i);
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
